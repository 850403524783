<template>
  <div id="tech">
    <div id="tech-title" class="mb-4 ms-4 ms-md-16">
      <div class="text-h2">My Tech Stack</div>
      <div class="text-h4 text--disabled mt-4" id="">
        Technologies And Tools I Use
      </div>
    </div>
    <div class="text-h6 text-md-h4 text-center mb-4 ms-4 ms-md-16" id="tools">
      Vue Node Express MongoDB Nuxt Vuetify BootStrap GSAP AnimeJS npm RESTful
      API postman i18n Vuex SPA SSR SSG Socket Cron Queue
    </div>
    <background id="tech-background" class="mb-8 mt-n16"></background>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6">
        <p
          class="
            text-body-1 text-md-h4
            font-weight-light
            mb-10
            text--secondary text-center
          "
        >
          In addition to JavaScript, I also have experience working with
          Node.js, which is a powerful tool with a rich ecosystem and a vibrant
          community. With Node.js, I can create server-side applications and
          APIs that seamlessly integrate with front-end applications, creating a
          comprehensive solution that meets all your development needs.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import background from './background.vue'

export default {
  name: 'tech',

  components: { background },

  methods: {
    animate() {
      //vue badge
      this.gsap.from('#vue-badge', {
        y: -100,
        x: -100,
        opacity: 0,
        scrollTrigger: {
          trigger: '#tech-title',
          toggleActions: 'play none none none',
          start: 'top center',
          scrub: 1,
        },
      })
      //js badge
      this.gsap.from('#js-badge', {
        y: -150,
        opacity: 0,
        scrollTrigger: {
          trigger: '#tech-title',
          toggleActions: 'play none none none',
          start: 'top center',
          scrub: 1,
        },
      })
      //html badge
      this.gsap.from('#html-badge', {
        x: 250,
        opacity: 0,
        scrollTrigger: {
          trigger: '#tech-title',
          toggleActions: 'play none none none',
          start: 'top center',
          scrub: 1,
        },
      })
      //css badge
      this.gsap.from('#css-badge', {
        x: 50,
        y: -50,
        opacity: 0,
        scrollTrigger: {
          trigger: '#tech-title',
          toggleActions: 'play none none none',
          start: 'top center',
          scrub: 1,
        },
      })
      //tab
      this.gsap.to('#tab', {
        scale: 0.8,
        scrollTrigger: {
          trigger: '#tech-title',
          toggleActions: 'play none none none',
          start: 'top center',
          scrub: 1,
        },
      })
      //bars
      this.gsap.from('#bars *', {
        scaleX: 0,
        transformOrigin: 'start',
        stagger: 0.2,
        scrollTrigger: {
          trigger: '#tech-title',
          toggleActions: 'play none none none',
          start: 'top center',
          scrub: 1,
        },
      })
      //cloud
      this.gsap.to('#cloud', {
        x: 10,
        y: 10,
        scrollTrigger: {
          trigger: '#tech-title',
          toggleActions: 'play none none none',
          start: 'top center',
          scrub: 1,
        },
      })
    },
  },

  mounted() {
    if (!this.$vuetify.breakpoint.mobile) this.animate()
  },
}
</script>

<style>
#tech-title {
  font-family: 'Big Shoulders Display', cursive;
}

#tools {
  color: #e0e0e0;
}
</style>
