<template>
  <v-container class="mt-16 pt-md-2">
    <p class="text-body-1 text-md-h3 text-center font-weight-light mb-16">
      As a full-stack developer, I am passionate about creating visually
      stunning and high-performing web applications. With a focus on both
      front-end design and back-end functionality, I deliver exceptional results
      with meticulous attention to detail and commitment to quality.
    </p>

    <!-- <div id="screen">
      <v-img :src="laptop" height="300px" contain />
    </div> -->

    <v-row
      justify="center"
      justify-md="space-between"
      style="min-height: 35rem"
      id="app-types"
      class="mb-16"
    >
      <v-col cols="8" md="3">
        <WebApps></WebApps>
      </v-col>
      <v-col cols="8" md="3">
        <AdminPanels></AdminPanels>
      </v-col>
      <v-col cols="8" md="3">
        <Websites></Websites>
      </v-col>
    </v-row>
    <p class="text-body-1 text-md-h4 font-weight-light mb-10 text--secondary">
      JavaScript is my primary language for developing exciting front-end
      applications. With expertise in the latest tools and components, I create
      optimized code that delivers high performance and an exceptional user
      experience. I have a deep understanding of JavaScript's core features and
      capabilities, gained through years of experience in web development.
    </p>
  </v-container>
</template>

<script>
import WebApps from './web-apps'
import Websites from './websites'
import AdminPanels from './admin-panels'
import laptop from '@/assets/images/laptop.png'

export default {
  name: 'description',

  components: {
    WebApps,
    Websites,
    AdminPanels,
  },

  data() {
    return {
      laptop,
    }
  },

  methods: {
    animate() {
      this.gsap.from('#app-types *', {
        y: 30,
        opacity: 0,
        ease: 'power3.out',
        stagger: {
          amount: 1,
          grid: 'auto',
          from: 'start',
        },
      })

      this.gsap.from('#screen', {
        scale: 0.4,
        // opacity: 0,
        ease: 'linear',
        scrollTrigger: {
          trigger: '#screen',
          toggleActions: 'restart none reverse none',
          start: 'top center',
          end: '+=1000',

          pin: true,
          // markers: true,
        },
      })
    },
  },

  mounted() {
    this.animate()
  },
}
</script>

<style>
#screen {
  width: 100%;
  min-height: 1000px;
}
</style>
