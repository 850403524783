<template>
  <div id="admin-panel" class="d-flex flex-column align-center">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 623 588"
      enable-background="new 0 0 623 588"
      xml:space="preserve"
    >
      <g id="Layer_2">
        <path
          fill="#C8CAD7"
          d="M407.911,573.59V580h-189.62v-5.221c32.279-11.959,48.751-47.822,36.791-80.102
		c-5.753-15.527-17.446-28.125-32.501-35.018h184.08c-31.307,14.367-45.04,51.393-30.674,82.699
		C382.409,556.354,393.78,567.477,407.911,573.59z"
        />
        <path
          opacity="0.1"
          enable-background="new    "
          d="M406.661,459.67c-17.14,7.865-29.805,23.057-34.46,41.33h-115.16
		c-4.655-18.273-17.32-33.465-34.46-41.33H406.661z"
        />
        <path
          fill="#C8CAD7"
          d="M1.121,427v36c0,19.881,14.55,36,32.5,36h558c17.95,0,32.5-16.119,32.5-36v-36H1.121z"
        />
        <path
          opacity="0.1"
          enable-background="new    "
          d="M407.911,573.59V580h-189.62v-5.221c5.198-1.93,10.115-4.545,14.62-7.779h163.4
		C399.932,569.605,403.82,571.814,407.911,573.59z"
        />
        <path
          fill="#C8CAD7"
          d="M165.121,569h296c5.247,0,9.5,4.254,9.5,9.5l0,0c0,5.246-4.253,9.5-9.5,9.5h-296
		c-5.247,0-9.5-4.254-9.5-9.5l0,0C155.621,573.254,159.874,569,165.121,569z"
        />
        <path
          fill="#474157"
          d="M623,32.5C623,14.551,608.449,0,590.5,0l0,0h-558C14.551,0,0,14.551,0,32.5l0,0v401.501h623V32.5z"
        />
        <path
          fill="#EDEDF4"
          d="M33.549,31.5h557c8.836,0,16,7.164,16,16v345c0,8.836-7.164,16-16,16h-557c-8.836,0-16-7.164-16-16v-345
		C17.549,38.663,24.712,31.5,33.549,31.5z"
        />
      </g>
      <g id="dashboard">
        <rect
          x="453.844"
          y="112.443"
          fill="#F2F2F2"
          width="140.687"
          height="107.481"
        />
        <rect
          x="460.835"
          y="117.784"
          fill="#FFFFFF"
          width="126.706"
          height="96.8"
        />
        <g id="pie">
          <path
            fill="#3F3D56"
            d="M526.413,189.585c0.017,0.001,0.033,0,0.051,0c18.631-0.975,32.945-16.869,31.97-35.5
			c-0.887-16.98-14.257-30.654-31.215-31.923c-0.214-0.017-0.425,0.057-0.582,0.203c-0.157,0.143-0.248,0.346-0.25,0.558
			l-0.738,65.884C525.644,189.232,525.987,189.581,526.413,189.585L526.413,189.585z"
          />
          <path
            fill="#E6E6E6"
            d="M499.919,133.055c0.264,0.003,0.514,0.111,0.698,0.299l22.682,23.195c0.184,0.187,0.285,0.441,0.282,0.704
			l-0.354,31.525c-0.003,0.273-0.118,0.534-0.32,0.717c-0.204,0.187-0.474,0.281-0.748,0.26
			c-18.728-1.401-32.772-17.719-31.371-36.446c0.553-7.374,3.492-14.366,8.375-19.918c0.183-0.206,0.443-0.328,0.719-0.336
			C499.895,133.055,499.906,133.055,499.919,133.055z"
          />
          <path
            fill="#6C63FF"
            d="M522.982,121.892c0.254,0.003,0.497,0.102,0.68,0.277c0.197,0.188,0.308,0.452,0.304,0.726l-0.318,28.444
			c-0.007,0.547-0.455,0.986-1.001,0.979c-0.265-0.003-0.514-0.11-0.698-0.298l-19.792-20.241c-0.383-0.392-0.375-1.021,0.018-1.403
			c0.012-0.012,0.023-0.023,0.037-0.034c5.755-5.067,13.053-8.044,20.709-8.45C522.942,121.892,522.963,121.892,522.982,121.892z"
          />
        </g>
        <g id="pie-labels">
          <path
            fill="#6C63FF"
            d="M512.786,203.248c-0.038,3.35-2.784,6.036-6.135,5.999c-3.351-0.037-6.037-2.784-5.999-6.135
			c0.037-3.35,2.784-6.037,6.135-5.999l0,0C510.137,197.154,512.82,199.898,512.786,203.248z"
          />
          <path
            fill="#3F3D56"
            d="M530.121,203.442c-0.037,3.351-2.784,6.037-6.136,5.999c-3.35-0.038-6.036-2.784-5.998-6.135
			s2.785-6.037,6.135-6l0,0C527.472,197.348,530.155,200.092,530.121,203.442z"
          />
          <path
            fill="#E6E6E6"
            d="M547.457,203.636c-0.038,3.352-2.785,6.037-6.136,6c-3.352-0.038-6.037-2.785-5.999-6.136
			c0.036-3.351,2.783-6.037,6.135-5.999l0,0C544.807,197.542,547.49,200.287,547.457,203.636z"
          />
        </g>
        <rect
          x="452.363"
          y="236.84"
          fill="#F2F2F2"
          width="140.687"
          height="107.481"
        />
        <rect
          x="459.354"
          y="242.181"
          fill="#FFFFFF"
          width="126.706"
          height="96.8"
        />
        <circle fill="#3F3D56" cx="480.853" cy="268.735" r="5.261" />
        <path
          fill="#3F3D56"
          d="M565.381,264.641c1.971-0.003,3.57,1.591,3.574,3.562c0.003,1.971-1.591,3.57-3.561,3.574
		c-0.005,0-0.01,0-0.014,0h-55.33c-1.972,0-3.569-1.598-3.569-3.568c0-1.971,1.598-3.568,3.569-3.568H565.381 M565.381,263.767
		h-55.33c-2.453-0.002-4.444,1.985-4.446,4.437c-0.003,2.454,1.984,4.444,4.437,4.446c0.004,0,0.007,0,0.01,0h55.33
		c2.453,0.003,4.443-1.984,4.445-4.437s-1.983-4.443-4.437-4.446C565.386,263.767,565.384,263.767,565.381,263.767z"
        />
        <circle fill="#3F3D56" cx="480.853" cy="290.581" r="5.261" />
        <path
          fill="#3F3D56"
          d="M565.381,286.486c1.971-0.003,3.57,1.591,3.574,3.562c0.003,1.971-1.591,3.571-3.561,3.574
		c-0.005,0-0.01,0-0.014,0h-55.33c-1.972,0-3.569-1.597-3.569-3.567c0-1.971,1.598-3.568,3.569-3.568H565.381 M565.381,285.613
		h-55.33c-2.453,0-4.442,1.988-4.442,4.441s1.989,4.441,4.442,4.441h55.33c2.453,0,4.442-1.988,4.442-4.441
		S567.834,285.613,565.381,285.613z"
        />
        <circle fill="#3F3D56" cx="480.853" cy="312.426" r="5.261" />
        <path
          fill="#3F3D56"
          d="M565.381,308.333c1.971-0.004,3.57,1.591,3.574,3.561c0.003,1.971-1.591,3.571-3.561,3.574
		c-0.005,0-0.01,0-0.014,0h-55.33c-1.972,0-3.569-1.598-3.569-3.568c0-1.971,1.598-3.567,3.569-3.567H565.381 M565.381,307.458
		h-55.33c-2.453,0-4.442,1.988-4.442,4.442c0,2.453,1.989,4.441,4.442,4.441h55.33c2.453,0,4.442-1.989,4.442-4.441
		C569.823,309.447,567.834,307.458,565.381,307.458z"
        />
        <g id="bars-2">
          <path
            fill="#6C63FF"
            d="M543.549,272.65h-41.653c-2.453,0.005-4.444-1.98-4.449-4.433s1.981-4.446,4.434-4.45
			c0.005,0,0.011,0,0.016,0h41.653c2.453,0.004,4.437,1.997,4.433,4.45C547.978,270.664,545.995,272.646,543.549,272.65z"
          />
          <path
            fill="#6C63FF"
            d="M559.276,294.496h-57.381c-2.453,0.004-4.444-1.98-4.449-4.434s1.981-4.445,4.434-4.45
			c0.005,0,0.011,0,0.016,0h57.381c2.453,0.004,4.438,1.997,4.436,4.45C563.706,292.509,561.725,294.492,559.276,294.496z"
          />
          <path
            fill="#6C63FF"
            d="M525.198,316.342h-23.303c-2.453,0.005-4.444-1.98-4.449-4.434s1.981-4.446,4.434-4.45
			c0.005,0,0.011,0,0.016,0h23.303c2.453,0.004,4.438,1.997,4.433,4.45C529.627,314.355,527.646,316.338,525.198,316.342z"
          />
        </g>
        <rect
          x="28.211"
          y="131.46"
          fill="#F2F2F2"
          width="393.053"
          height="201.44"
        />
        <rect
          x="41.313"
          y="141.469"
          fill="#FFFFFF"
          width="366.85"
          height="181.422"
        />
        <path
          fill="#3F3D56"
          d="M362.666,295.212H95.239c-0.462,0-0.837-0.375-0.837-0.837V166.198c-0.001-0.462,0.373-0.838,0.834-0.84
		c0.463-0.001,0.838,0.373,0.84,0.834c0,0.002,0,0.003,0,0.005v127.341h266.589c0.462,0,0.837,0.374,0.837,0.836
		S363.128,295.212,362.666,295.212z"
        />
        <g id="bars-1">
          <path
            fill="#6C63FF"
            d="M152.377,286.006h-24.323c-1.372-0.001-2.483-1.113-2.485-2.484v-33.53
			c0.001-1.371,1.113-2.482,2.485-2.483h24.323c1.372,0.001,2.483,1.112,2.484,2.483v33.53
			C154.86,284.894,153.749,286.005,152.377,286.006z"
          />
          <path
            fill="#6C63FF"
            d="M196.734,286.006H172.41c-1.372-0.001-2.482-1.113-2.484-2.484v-65.333
			c0.002-1.372,1.113-2.483,2.484-2.485h24.324c1.371,0.001,2.483,1.113,2.484,2.485v65.333
			C199.217,284.894,198.105,286.005,196.734,286.006z"
          />
          <path
            fill="#6C63FF"
            d="M241.091,286.006h-24.324c-1.372-0.001-2.483-1.113-2.484-2.484v-33.53
			c0.001-1.371,1.113-2.482,2.484-2.483h24.324c1.371,0.001,2.482,1.112,2.484,2.483v33.53
			C243.573,284.894,242.462,286.005,241.091,286.006z"
          />
          <path
            fill="#6C63FF"
            d="M285.447,286.006h-24.323c-1.34,0.029-2.451-1.032-2.484-2.372v-78.112c0.033-1.339,1.144-2.4,2.484-2.372
			h24.323c1.341-0.029,2.451,1.032,2.485,2.372v78.112C287.898,284.974,286.788,286.035,285.447,286.006z"
          />
          <path
            fill="#6C63FF"
            d="M329.805,286.006h-24.324c-1.371-0.001-2.483-1.113-2.484-2.484V183.039
			c0.001-1.372,1.113-2.483,2.484-2.485h24.324c1.371,0.001,2.482,1.113,2.484,2.485v100.483
			C332.287,284.894,331.175,286.005,329.805,286.006z"
          />
        </g>
        <circle fill="#3F3D56" cx="140.215" cy="232.444" r="5.021" />
        <circle fill="#3F3D56" cx="184.572" cy="199.803" r="5.021" />
        <circle fill="#3F3D56" cx="228.929" cy="232.444" r="5.021" />
        <circle fill="#3F3D56" cx="273.286" cy="183.902" r="5.022" />
        <circle fill="#3F3D56" cx="317.643" cy="165.489" r="5.021" />
        <polygon
          fill="#3F3D56"
          points="229.035,233.568 184.572,200.265 140.717,233.113 139.713,231.774 184.572,198.173 
		228.822,231.318 272.797,183.197 272.972,183.125 317.33,165.254 317.956,166.806 273.775,184.607 	"
        />
      </g>
    </svg>
    <div
      class="text-h4 text-center mt-4 font-weight-bold secondary--text"
      id="title"
    >
      Admin Panels
    </div>
    <div class="text-h5 text-center text--disabled" id="text">
      Whether it's content management or Dashboard, show and administer your
      data through powerful dashboards and easy-to-use forms built with
      top-notch technologies with security features and fast performance
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tl: null,
      textTl: null,
    }
  },

  methods: {
    setup() {
      this.tl = this.gsap.timeline({ paused: true })
      this.tl
        .from('#admin-panel #bars-1 *', {
          scaleY: 0.0,
          transformOrigin: 'bottom',
          stagger: {
            amount: 0.2,
            grid: 'auto',
            from: 'start',
          },
        })
        .from('#admin-panel #bars-2 *', {
          scaleX: 0.5,
          duration: 0.2,
          stagger: {
            amount: 0.2,
            grid: 'auto',
            from: 'start',
          },
        })
        .to('#admin-panel #pie-labels *', {
          opacity: 0.2,
          duration: 0.2,
          stagger: {
            amount: 0.5,
            grid: 'auto',
            from: 'start',
          },
        })
        .to('#admin-panel #pie', {
          rotation: 20,
          transformOrigin: '50% 50%',
          duration: 0.2,
        })

      this.textTl = this.gsap.timeline({ paused: true })
      this.textTl.from('#admin-panel #title', {
        opacity: 0.1,
        y: 30,
        duration: 0.2,
      })
      this.textTl.from('#admin-panel #text', {
        opacity: 0.05,
        y: 30,
        duration: 0.3,
      })

      const element = document.getElementById('admin-panel')
      element.addEventListener('mouseenter', () => {
        this.tl.play()
        this.textTl.play()
      })
      element.addEventListener('mouseleave', () => {
        this.tl.reverse()
        this.textTl.reverse()
      })
    },
  },

  mounted() {
    this.setup()
  },
}
</script>

<style></style>
