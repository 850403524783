<template>
  <div>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 1366 768"
      enable-background="new 0 0 1366 768"
      xml:space="preserve"
    >
      <g opacity="0.7">
        <g id="bars">
          <polyline
            fill="#FF2C9C"
            points="827.578,256.711 644.951,362.151 644.978,371.816 827.604,266.377 827.578,256.711 		"
          />
          <polyline
            fill="#42E8E0"
            points="780.127,302.108 645,380.124 645.028,389.773 780.154,311.76 780.127,302.108 		"
          />
          <polyline
            fill="#FF2C9C"
            points="755.429,335.462 645.055,399.186 645.083,408.853 755.454,345.128 755.429,335.462 		"
          />
          <polyline
            fill="#42E8E0"
            points="733.633,367.152 645.109,418.263 645.136,427.914 733.662,376.803 733.633,367.152 		"
          />
          <polyline
            fill="#FF2C9C"
            points="708.712,400.635 645.162,437.324 645.19,446.99 708.739,410.3 708.712,400.635 		"
          />
        </g>
        <g>
          <polyline
            fill="#6464DD"
            points="499.59,446.075 499.831,531.127 625.153,458.771 624.955,388.606 499.631,460.963 
			499.59,446.075 		"
          />
          <polyline
            fill="#42E8E0"
            points="624.912,373.721 499.59,446.075 499.631,460.963 624.955,388.606 624.912,373.721 		"
          />
        </g>
      </g>
      <g>
        <path
          fill="#4343C4"
          d="M762.75,625.568c0-3.892,0-6.214,0-6.214l302.447,70.979v9.656c0,2.531-2.188,4.037-4.106,5.146
		l-81.688,47.162c-2.54,1.467-5.672,1.467-8.213,0L766.857,634.325C763.361,632.31,762.75,629.455,762.75,625.568z"
        />
        <path
          fill="#6464DD"
          d="M766.857,612.239l81.687-47.16c2.543-1.468,5.673-1.468,8.215,0l204.332,117.97
		c5.475,3.162,5.475,11.067,0,14.229l-81.688,47.162c-2.54,1.468-5.672,1.468-8.213,0L766.857,626.466
		C761.38,623.308,761.38,615.402,766.857,612.239z"
        />
        <g>
          <g>
            <g>
              <path
                fill="#A5A5F2"
                d="M862.63,576.287v2.771c0,0.67-0.358,1.291-0.937,1.633l-13.379,8.06c-0.426,0.263-0.966,0.263-1.392,0
					l-13.159-7.575c-0.414-0.235-0.67-0.67-0.67-1.146v-3.087L862.63,576.287z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M881.882,587.63v2.118c0,0.672-0.356,1.295-0.935,1.634l-13.401,8.374c-0.427,0.262-0.964,0.262-1.39,0
					l-13.141-7.379c-0.414-0.232-0.668-0.67-0.668-1.146v-3.594L881.882,587.63z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M901.603,598.826v2.77c0,0.672-0.356,1.293-0.937,1.634l-13.398,8.062c-0.429,0.262-0.967,0.262-1.393,0
					l-13.139-7.576c-0.413-0.234-0.669-0.672-0.669-1.145v-3.087L901.603,598.826z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M921.214,610.055v2.772c0,0.672-0.358,1.293-0.937,1.633l-13.397,8.114
					c-0.429,0.262-0.966,0.262-1.393,0l-13.141-7.631c-0.413-0.232-0.67-0.67-0.67-1.145v-3.085L921.214,610.055z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M940.149,621.07v2.771c-0.002,0.67-0.359,1.291-0.938,1.633l-13.4,8.061
					c-0.428,0.262-0.964,0.262-1.39,0l-13.142-7.578c-0.412-0.232-0.669-0.668-0.669-1.144v-3.086L940.149,621.07z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M959.563,632.255v2.771c0,0.672-0.357,1.293-0.938,1.633l-13.569,7.961
					c-0.429,0.262-0.965,0.262-1.392,0l-12.969-7.479c-0.415-0.229-0.671-0.669-0.671-1.141v-3.089L959.563,632.255z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M978.991,643.438v2.77c0,0.675-0.357,1.296-0.938,1.634l-13.399,8.062c-0.428,0.262-0.965,0.262-1.391,0
					l-13.139-7.576c-0.414-0.232-0.67-0.67-0.67-1.145v-3.085L978.991,643.438z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M998.891,654.976v2.772c-0.002,0.671-0.357,1.29-0.936,1.631l-13.401,8.062
					c-0.427,0.262-0.964,0.262-1.39,0l-13.141-7.576c-0.412-0.234-0.669-0.67-0.669-1.145v-3.087L998.891,654.976z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M1018.587,666.32v2.772c0,0.671-0.356,1.295-0.934,1.633l-13.452,8.062
					c-0.427,0.262-0.964,0.262-1.393,0l-13.088-7.578c-0.414-0.232-0.668-0.67-0.668-1.143v-3.089L1018.587,666.32z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M1037.711,677.359v2.77c0,0.672-0.356,1.293-0.935,1.633l-13.401,8.062c-0.426,0.26-0.965,0.26-1.39,0
					l-13.141-7.575c-0.414-0.234-0.669-0.672-0.669-1.146v-3.089L1037.711,677.359z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M1057.289,688.643v2.771c-0.003,0.672-0.357,1.293-0.937,1.634l-13.481,8.062
					c-0.426,0.262-0.964,0.262-1.389-0.002l-13.063-7.576c-0.412-0.232-0.668-0.668-0.668-1.143v-3.087L1057.289,688.643z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M846.903,585.386l-13.462-7.776c-0.466-0.285-0.466-0.961,0.002-1.246l13.758-8.459
				c0.424-0.261,0.958-0.261,1.384,0l13.751,8.029c0.459,0.281,0.459,0.949,0,1.232l-14.044,8.224
				C847.865,585.652,847.329,585.652,846.903,585.386z"
            />
            <path
              fill="#FFFFFF"
              d="M866.13,596.365l-13.462-7.778c-0.464-0.286-0.462-0.962,0-1.247l13.76-8.459
				c0.426-0.26,0.961-0.26,1.383,0.002l13.755,8.026c0.458,0.283,0.458,0.948,0,1.231l-14.045,8.225
				C867.095,596.627,866.556,596.627,866.13,596.365z"
            />
            <path
              fill="#FFFFFF"
              d="M885.875,607.873l-13.461-7.779c-0.464-0.285-0.464-0.959,0.002-1.246l13.759-8.457
				c0.424-0.26,0.958-0.26,1.383,0l13.752,8.028c0.461,0.283,0.461,0.95,0,1.231l-14.042,8.225
				C886.839,608.137,886.301,608.135,885.875,607.873z"
            />
            <path
              fill="#FFFFFF"
              d="M905.487,619.14l-13.461-7.777c-0.467-0.287-0.467-0.963,0-1.248l13.76-8.457
				c0.425-0.262,0.957-0.262,1.383,0l13.753,8.026c0.457,0.282,0.457,0.952-0.002,1.231l-14.04,8.225
				C906.451,619.401,905.914,619.401,905.487,619.14z"
            />
            <path
              fill="#FFFFFF"
              d="M924.422,630.036l-13.462-7.778c-0.465-0.287-0.465-0.964,0-1.249l13.76-8.457
				c0.424-0.262,0.959-0.262,1.384,0l13.751,8.026c0.461,0.285,0.461,0.953,0,1.235l-14.043,8.223
				C925.384,630.298,924.848,630.296,924.422,630.036z"
            />
            <path
              fill="#FFFFFF"
              d="M943.812,641.373l-13.462-7.777c-0.467-0.287-0.466-0.963,0-1.248l13.76-8.457
				c0.423-0.262,0.958-0.262,1.382,0l13.753,8.025c0.461,0.283,0.457,0.953,0,1.232l-14.044,8.225
				C944.773,641.635,944.237,641.635,943.812,641.373z"
            />
            <path
              fill="#FFFFFF"
              d="M963.264,652.561l-13.46-7.776c-0.466-0.287-0.466-0.961,0.001-1.248l13.757-8.459
				c0.425-0.258,0.959-0.258,1.385,0.002l13.753,8.025c0.457,0.283,0.457,0.951-0.002,1.232l-14.043,8.227
				C964.227,652.823,963.689,652.823,963.264,652.561z"
            />
            <path
              fill="#FFFFFF"
              d="M983.138,664.028l-13.461-7.78c-0.465-0.285-0.465-0.962,0.002-1.247l13.758-8.457
				c0.422-0.262,0.956-0.262,1.382,0l13.755,8.026c0.457,0.283,0.457,0.953-0.003,1.231l-14.043,8.227
				C984.101,664.288,983.563,664.288,983.138,664.028z"
            />
            <path
              fill="#FFFFFF"
              d="M1002.862,675.409l-13.462-7.776c-0.465-0.285-0.465-0.964,0-1.249l13.759-8.459
				c0.424-0.26,0.958-0.26,1.383,0.002l13.755,8.028c0.458,0.281,0.458,0.948-0.002,1.231l-14.043,8.223
				C1003.825,675.673,1003.288,675.673,1002.862,675.409z"
            />
            <path
              fill="#FFFFFF"
              d="M1021.985,686.445l-13.461-7.776c-0.466-0.285-0.466-0.964,0-1.249l13.759-8.456
				c0.423-0.263,0.959-0.263,1.381,0l13.756,8.027c0.458,0.281,0.458,0.949-0.001,1.231l-14.044,8.223
				C1022.949,686.71,1022.41,686.71,1021.985,686.445z"
            />
            <path
              fill="#FFFFFF"
              d="M1041.562,697.623l-13.462-7.778c-0.463-0.285-0.463-0.964,0.002-1.246l13.757-8.457
				c0.426-0.265,0.961-0.265,1.384,0l13.754,8.025c0.46,0.283,0.46,0.951,0,1.234l-14.045,8.222
				C1042.526,697.886,1041.987,697.886,1041.562,697.623z"
            />
          </g>
          <g>
            <g>
              <path
                fill="#A5A5F2"
                d="M840.802,588.916v2.77c0,0.672-0.355,1.293-0.934,1.633l-13.384,8.063c-0.426,0.262-0.963,0.262-1.389,0
					l-13.16-7.576c-0.415-0.234-0.672-0.672-0.672-1.146v-3.086L840.802,588.916z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M860.053,600.255v2.123c0,0.672-0.354,1.293-0.935,1.631l-13.4,8.376c-0.426,0.263-0.964,0.263-1.39,0
					l-13.142-7.381c-0.412-0.23-0.669-0.668-0.669-1.144v-3.596L860.053,600.255z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M918.32,633.699v2.772c0,0.669-0.356,1.29-0.936,1.631l-13.399,8.062c-0.428,0.26-0.965,0.26-1.392,0
					l-13.141-7.576c-0.412-0.234-0.669-0.672-0.669-1.145v-3.087L918.32,633.699z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M937.733,644.882v2.771c0,0.674-0.356,1.293-0.934,1.633l-13.573,7.963
					c-0.426,0.262-0.964,0.262-1.389,0l-12.971-7.479c-0.414-0.23-0.668-0.668-0.668-1.144v-3.086L937.733,644.882z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M957.163,656.068v2.77c0,0.672-0.355,1.293-0.935,1.633l-13.4,8.062
					c-0.428,0.263-0.966,0.263-1.392-0.002l-13.14-7.576c-0.415-0.231-0.671-0.669-0.671-1.143v-3.087L957.163,656.068z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M977.063,667.605v2.77c0,0.672-0.357,1.295-0.938,1.633l-8.039,11.036c-0.429,0.26-0.965,0.26-1.393,0
					l-18.498-10.553c-0.415-0.229-0.671-0.669-0.671-1.143v-3.087L977.063,667.605z"
              />
            </g>
            <path
              fill="#A5A5F2"
              d="M945.135,692.095v3.087c0,0.473,0.257,0.91,0.669,1.143l13.088,7.576c0.429,0.264,0.965,0.264,1.392,0
				l35.54-20.546c0.581-0.34,0.935-0.961,0.935-1.633l0.002-2.771L945.135,692.095z"
            />
            <g>
              <path
                fill="#A5A5F2"
                d="M1015.884,689.986l-0.002,2.771c0,0.672-0.353,1.295-0.934,1.633l-13.4,8.063
					c-0.427,0.262-0.965,0.26-1.391-0.002l-13.142-7.576c-0.412-0.232-0.668-0.67-0.668-1.143v-3.089L1015.884,689.986z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M1035.461,701.271l-0.002,2.771c0,0.674-0.355,1.295-0.937,1.635l-13.478,8.062
					c-0.427,0.262-0.965,0.262-1.392-0.002l-13.06-7.576c-0.412-0.232-0.669-0.669-0.669-1.143v-3.089L1035.461,701.271z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M825.075,598.016l-13.46-7.777c-0.465-0.285-0.465-0.961,0-1.248l13.759-8.457
				c0.424-0.26,0.958-0.26,1.383,0.002l13.752,8.026c0.46,0.282,0.459,0.948,0,1.231l-14.042,8.225
				C826.039,598.279,825.502,598.279,825.075,598.016z"
            />
            <path
              fill="#FFFFFF"
              d="M844.302,608.994l-13.461-7.778c-0.465-0.285-0.465-0.961,0.001-1.249l13.757-8.456
				c0.425-0.261,0.961-0.261,1.386,0l13.752,8.025c0.461,0.283,0.461,0.953,0,1.233l-14.045,8.225
				C845.266,609.254,844.728,609.254,844.302,608.994z"
            />
            <g>
              <path
                fill="#A5A5F2"
                d="M879.505,611.943l-0.026,2.126c0,0.672-0.356,1.295-0.935,1.633l-13.401,8.374
					c-0.427,0.263-0.964,0.263-1.389,0l-13.143-7.379c-0.415-0.232-0.67-0.67-0.67-1.146v-3.593L879.505,611.943z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M863.727,620.684l-13.461-7.776c-0.466-0.285-0.466-0.962,0-1.247l13.758-8.459
				c0.424-0.26,0.959-0.26,1.383,0.003l13.756,8.025c0.457,0.283,0.457,0.953-0.002,1.231l-14.043,8.227
				C864.688,620.947,864.152,620.947,863.727,620.684z"
            />
            <g>
              <path
                fill="#A5A5F2"
                d="M898.459,623.806l0.319,1.608c0,0.672-0.357,1.295-0.936,1.633l-13.401,8.377
					c-0.427,0.262-0.964,0.262-1.391-0.005l-13.139-7.377c-0.413-0.23-0.67-0.67-0.67-1.143v-3.599L898.459,623.806z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M883.025,632.03l-13.459-7.776c-0.467-0.287-0.467-0.961,0-1.248l13.759-8.459
				c0.421-0.258,0.956-0.258,1.382,0.002l13.754,8.025c0.457,0.283,0.457,0.951-0.002,1.231l-14.042,8.227
				C883.989,632.293,883.451,632.293,883.025,632.03z"
            />
            <path
              fill="#FFFFFF"
              d="M902.594,642.663l-13.46-7.776c-0.467-0.287-0.465-0.963,0-1.248l13.759-8.459
				c0.425-0.258,0.959-0.258,1.383,0l13.753,8.029c0.457,0.283,0.457,0.951-0.002,1.232l-14.041,8.222
				C903.558,642.928,903.021,642.926,902.594,642.663z"
            />
            <path
              fill="#FFFFFF"
              d="M921.981,654.002l-13.459-7.778c-0.466-0.286-0.465-0.962,0-1.249l13.757-8.457
				c0.426-0.26,0.962-0.26,1.383,0l13.756,8.028c0.458,0.281,0.457,0.95,0,1.231l-14.045,8.225
				C922.947,654.264,922.407,654.264,921.981,654.002z"
            />
            <path
              fill="#FFFFFF"
              d="M941.437,665.188l-13.459-7.774c-0.466-0.287-0.466-0.964,0-1.249l13.757-8.459
				c0.425-0.26,0.959-0.26,1.383,0.002l13.754,8.026c0.459,0.283,0.458,0.948,0,1.231l-14.043,8.225
				C942.4,665.452,941.862,665.452,941.437,665.188z"
            />
            <path
              fill="#FFFFFF"
              d="M996.415,678.582l-33.424-19.41c-0.424-0.26-0.959-0.26-1.382,0l-13.76,8.459
				c-0.466,0.285-0.466,0.961,0,1.246l18.192,10.523l-20.559,12.099c-0.465,0.285-0.465,0.961,0,1.246l13.462,7.779
				c0.426,0.262,0.965,0.264,1.389,0l36.081-20.71C996.875,679.533,996.875,678.865,996.415,678.582z"
            />
            <path
              fill="#FFFFFF"
              d="M1000.157,699.075l-13.462-7.777c-0.463-0.287-0.463-0.963,0-1.25l13.759-8.457
				c0.426-0.26,0.961-0.26,1.385,0.002l13.753,8.026c0.46,0.282,0.46,0.95,0,1.231l-14.044,8.225
				C1001.121,699.337,1000.583,699.337,1000.157,699.075z"
            />
            <path
              fill="#FFFFFF"
              d="M1019.731,710.253l-13.458-7.779c-0.466-0.285-0.466-0.961,0-1.246l13.759-8.459
				c0.423-0.26,0.959-0.26,1.385,0l13.751,8.028c0.458,0.283,0.458,0.95,0,1.231l-14.044,8.225
				C1020.697,710.515,1020.161,710.515,1019.731,710.253z"
            />
          </g>
          <g>
            <g>
              <path
                fill="#A5A5F2"
                d="M818.712,601.399v2.774c0,0.672-0.356,1.29-0.935,1.633l-13.38,8.062
					c-0.426,0.26-0.966,0.26-1.392-0.004l-13.158-7.576c-0.415-0.23-0.671-0.667-0.671-1.143v-3.087L818.712,601.399z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M837.965,612.744v2.119c0,0.672-0.354,1.295-0.934,1.633l-13.402,8.377
					c-0.425,0.26-0.964,0.26-1.39-0.004l-13.141-7.378c-0.413-0.232-0.669-0.67-0.669-1.143v-3.598L837.965,612.744z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M877.297,635.17v2.771c0,0.672-0.355,1.295-0.937,1.634l-13.398,8.114c-0.429,0.264-0.965,0.262-1.392,0
					l-13.14-7.631c-0.414-0.232-0.671-0.67-0.671-1.143v-3.089L877.297,635.17z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M896.231,646.186v2.77c0,0.674-0.356,1.295-0.937,1.633l-13.399,8.062c-0.429,0.263-0.966,0.263-1.391,0
					l-13.139-7.575c-0.415-0.232-0.672-0.67-0.672-1.146v-3.087L896.231,646.186z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M915.646,657.369v2.772c0,0.669-0.355,1.292-0.935,1.633l-13.571,7.962
					c-0.429,0.263-0.966,0.263-1.393-0.002l-12.967-7.479c-0.415-0.232-0.672-0.668-0.672-1.143v-3.087L915.646,657.369z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M935.073,668.554v2.771c0,0.672-0.356,1.293-0.935,1.634l-13.4,8.062
					c-0.429,0.262-0.963,0.26-1.39-0.002l-13.141-7.576c-0.414-0.232-0.67-0.672-0.67-1.146v-3.086L935.073,668.554z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M954.974,680.091v2.771c0,0.67-0.358,1.293-0.937,1.633l-13.401,8.061c-0.427,0.262-0.963,0.262-1.389,0
					l-13.14-7.578c-0.414-0.23-0.671-0.668-0.671-1.144v-3.084L954.974,680.091z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M993.794,702.474v2.772c0,0.669-0.354,1.29-0.935,1.633l-13.401,8.06c-0.426,0.262-0.965,0.262-1.391,0
					l-13.14-7.578c-0.412-0.232-0.668-0.67-0.668-1.145v-3.085L993.794,702.474z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M1013.372,713.76v2.77c0,0.674-0.356,1.293-0.938,1.633l-13.48,8.063c-0.426,0.262-0.964,0.262-1.389,0
					l-13.06-7.576c-0.414-0.234-0.671-0.672-0.671-1.146v-3.086L1013.372,713.76z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M802.986,610.503l-13.461-7.776c-0.466-0.287-0.466-0.964,0.001-1.249l13.759-8.457
				c0.422-0.264,0.957-0.264,1.383,0l13.754,8.026c0.457,0.283,0.457,0.95-0.003,1.233l-14.041,8.223
				C803.948,610.765,803.411,610.765,802.986,610.503z"
            />
            <path
              fill="#FFFFFF"
              d="M822.213,621.48l-13.462-7.777c-0.464-0.287-0.463-0.963,0-1.248l13.76-8.457
				c0.426-0.262,0.96-0.262,1.383,0l13.755,8.026c0.459,0.282,0.458,0.95,0,1.231l-14.045,8.225
				C823.179,621.742,822.639,621.742,822.213,621.48z"
            />
            <g>
              <path
                fill="#A5A5F2"
                d="M857.712,624.273v2.125c0,0.672-0.356,1.293-0.937,1.633l-13.4,8.375
					c-0.426,0.262-0.966,0.262-1.391-0.003l-13.139-7.379c-0.412-0.23-0.669-0.668-0.669-1.141v-3.599L857.712,624.273z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M841.957,633.013l-13.458-7.774c-0.466-0.287-0.466-0.963,0-1.25l13.759-8.457
				c0.423-0.263,0.958-0.263,1.383,0.002l13.753,8.025c0.459,0.283,0.459,0.951,0,1.232l-14.043,8.224
				C842.922,633.277,842.387,633.277,841.957,633.013z"
            />
            <path
              fill="#FFFFFF"
              d="M861.57,644.254l-13.461-7.776c-0.467-0.285-0.464-0.963,0-1.248l13.76-8.459
				c0.425-0.261,0.958-0.261,1.383,0.002l13.753,8.027c0.46,0.281,0.456,0.949,0,1.232l-14.043,8.222
				C862.533,644.517,861.997,644.517,861.57,644.254z"
            />
            <path
              fill="#FFFFFF"
              d="M880.505,655.151l-13.461-7.779c-0.465-0.285-0.465-0.961,0.002-1.248l13.757-8.457
				c0.424-0.262,0.959-0.262,1.385,0l13.753,8.028c0.457,0.28,0.457,0.95-0.002,1.231l-14.043,8.225
				C881.467,655.413,880.93,655.413,880.505,655.151z"
            />
            <path
              fill="#FFFFFF"
              d="M899.895,666.487l-13.462-7.776c-0.465-0.285-0.465-0.963,0-1.249l13.76-8.456
				c0.422-0.263,0.958-0.263,1.382,0l13.753,8.027c0.46,0.281,0.46,0.949,0,1.232l-14.044,8.222
				C900.856,666.752,900.32,666.752,899.895,666.487z"
            />
            <path
              fill="#FFFFFF"
              d="M919.349,677.676l-13.462-7.778c-0.466-0.285-0.466-0.962,0.001-1.247l13.759-8.456
				c0.422-0.26,0.957-0.26,1.383,0l13.753,8.027c0.457,0.283,0.457,0.949-0.002,1.231l-14.042,8.225
				C920.31,677.94,919.775,677.938,919.349,677.676z"
            />
            <path
              fill="#FFFFFF"
              d="M939.221,689.142l-13.462-7.777c-0.465-0.287-0.465-0.961,0.002-1.248l13.759-8.457
				c0.423-0.262,0.957-0.262,1.383,0l13.753,8.026c0.459,0.282,0.459,0.95-0.002,1.233l-14.041,8.225
				C940.186,689.403,939.646,689.403,939.221,689.142z"
            />
            <path
              fill="#FFFFFF"
              d="M978.067,711.561l-13.46-7.779c-0.466-0.282-0.464-0.959,0-1.246l13.759-8.457
				c0.425-0.26,0.96-0.26,1.383,0l13.754,8.028c0.458,0.283,0.458,0.95-0.001,1.231l-14.044,8.225
				C979.032,711.824,978.493,711.824,978.067,711.561z"
            />
            <path
              fill="#FFFFFF"
              d="M997.645,722.738l-13.462-7.778c-0.464-0.285-0.464-0.962,0.002-1.247l13.757-8.457
				c0.427-0.262,0.961-0.262,1.385,0l13.753,8.026c0.46,0.283,0.46,0.95,0,1.231l-14.046,8.225
				C998.608,723,998.07,723,997.645,722.738z"
            />
          </g>
          <g>
            <g>
              <path
                fill="#A5A5F2"
                d="M796.964,614.229v2.77c-0.002,0.672-0.358,1.293-0.936,1.633l-13.381,8.063
					c-0.426,0.262-0.964,0.262-1.39,0l-13.161-7.576c-0.414-0.234-0.67-0.672-0.67-1.146v-3.086L796.964,614.229z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M816.24,625.568l-0.025,2.123c0,0.67-0.355,1.293-0.934,1.631l-13.401,8.377
					c-0.427,0.262-0.965,0.262-1.389,0l-13.142-7.38c-0.416-0.232-0.669-0.67-0.669-1.146v-3.596L816.24,625.568z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M933.243,692.752v2.771c0,0.672-0.356,1.293-0.934,1.633l-13.4,8.063
					c-0.429,0.262-0.966,0.262-1.393-0.003l-110.447-63.561c-0.412-0.234-0.669-0.669-0.669-1.143v-3.089L933.243,692.752z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M952.921,704.264v2.771c0,0.672-0.356,1.293-0.935,1.633l-13.453,8.063
					c-0.426,0.262-0.965,0.262-1.391-0.003l-13.088-7.575c-0.415-0.232-0.671-0.67-0.671-1.146v-3.087L952.921,704.264z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M972.044,715.3v2.771c0,0.672-0.355,1.293-0.935,1.634l-13.4,8.062c-0.429,0.262-0.965,0.26-1.391-0.002
					l-13.141-7.576c-0.414-0.232-0.669-0.67-0.669-1.143v-3.089L972.044,715.3z"
              />
            </g>
            <g>
              <path
                fill="#A5A5F2"
                d="M991.621,726.585l-0.001,2.771c0,0.674-0.354,1.295-0.935,1.635l-13.479,8.062
					c-0.426,0.263-0.966,0.263-1.392-0.002l-13.061-7.576c-0.412-0.232-0.667-0.667-0.667-1.143v-3.087L991.621,726.585z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M781.236,623.328l-13.461-7.776c-0.466-0.285-0.466-0.961,0.001-1.248l13.758-8.457
				c0.424-0.26,0.959-0.26,1.385,0.002l13.751,8.026c0.459,0.283,0.459,0.948,0,1.231l-14.044,8.225
				C782.199,623.593,781.662,623.593,781.236,623.328z"
            />
            <path
              fill="#FFFFFF"
              d="M800.465,634.308l-13.462-7.778c-0.466-0.285-0.466-0.961,0-1.249l13.759-8.459
				c0.423-0.257,0.958-0.257,1.382,0.003l13.756,8.025c0.457,0.283,0.457,0.953-0.003,1.234l-14.043,8.224
				C801.428,634.567,800.89,634.567,800.465,634.308z"
            />
            <path
              fill="#FFFFFF"
              d="M917.549,701.809l-110.802-63.771c-0.464-0.283-0.464-0.962,0.002-1.247l13.76-8.457
				c0.423-0.262,0.958-0.262,1.383,0l111.092,64.02c0.46,0.283,0.457,0.95,0,1.231l-14.045,8.225
				C918.513,702.07,917.973,702.07,917.549,701.809z"
            />
            <path
              fill="#FFFFFF"
              d="M937.195,713.35l-13.461-7.774c-0.466-0.287-0.466-0.963,0-1.251l13.758-8.456
				c0.424-0.26,0.96-0.26,1.384,0.002l13.754,8.025c0.458,0.283,0.458,0.951-0.001,1.231l-14.044,8.225
				C938.158,713.614,937.62,713.614,937.195,713.35z"
            />
            <path
              fill="#FFFFFF"
              d="M956.318,724.386l-13.461-7.774c-0.465-0.287-0.465-0.963,0-1.25l13.759-8.457
				c0.425-0.26,0.96-0.26,1.383,0.002l13.755,8.025c0.457,0.283,0.457,0.951-0.001,1.232l-14.044,8.225
				C957.28,724.65,956.744,724.65,956.318,724.386z"
            />
            <path
              fill="#FFFFFF"
              d="M975.895,735.564l-13.461-7.777c-0.463-0.285-0.463-0.961,0-1.246l13.759-8.459
				c0.426-0.26,0.96-0.26,1.384,0l13.755,8.028c0.458,0.283,0.458,0.948,0,1.231l-14.047,8.225
				C976.858,735.828,976.319,735.828,975.895,735.564z"
            />
          </g>
        </g>
      </g>
      <g id="js-badge">
        <g enable-background="new    ">
          <defs>
            <polygon
              id="SVGID_1_"
              enable-background="new    "
              points="273.099,766.654 272.747,642.304 411.841,562 412.193,686.35 			"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_1_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_2_)">
            <path
              fill="#F0FF2E"
              d="M277.038,746.918c0.729,0.578,1.538,1.03,2.422,1.34L277.038,746.918 M385.636,577.13l-95.646,55.216
				c-9.531,5.503-17.22,18.818-17.187,29.773l0.209,74.425c0.013,4.11,1.114,7.304,2.986,9.39l5.96-10.695l-0.193-67.983
				c-0.032-10.948,7.683-24.278,17.212-29.784l95.648-55.222c0.037-0.023,0.072-0.042,0.107-0.063l2.398-6.643l1.586,0.913
				C395.543,574.073,390.86,574.109,385.636,577.13"
            />
            <path
              fill="#F0FF2E"
              d="M397.131,575.543l-2.398,6.643c4.164-2.375,7.981-2.855,10.955-1.72l-6.97-4.01L397.131,575.543"
            />
            <path
              fill="#F0FF2E"
              d="M281.958,735.238l-5.96,10.695l-0.173,0.312l1.212,0.673l2.422,1.34l6.018,3.33
				c-2.187-2.058-3.488-5.437-3.501-9.909L281.958,735.238"
            />
            <path
              fill="#FFFFB0"
              d="M299.007,647.767l95.648-55.225c4.569-2.637,8.296-0.511,8.313,4.737l0.21,74.426
				c0,0.05,0,0.087-0.002,0.118c-0.041,5.229-3.718,11.565-8.257,14.185l-95.647,55.217c-4.595,2.656-8.32,0.535-8.336-4.713
				l-0.212-74.432C290.709,656.831,294.412,650.422,299.007,647.767 M394.625,582.249l-95.648,55.222
				c-9.53,5.506-17.245,18.836-17.212,29.784l0.193,67.983l0.018,6.44c0.013,4.473,1.315,7.852,3.501,9.909
				c3.167,2.984,8.187,3.193,13.823-0.063l95.649-55.222c9.532-5.503,17.217-18.818,17.187-29.772l-0.213-74.423
				c-0.014-6.117-2.437-10.193-6.236-11.642c-2.974-1.136-6.791-0.655-10.955,1.72C394.698,582.207,394.662,582.226,394.625,582.249
				"
            />
            <path
              fill="#FFEF2E"
              d="M299.007,647.767c-4.595,2.655-8.298,9.064-8.284,14.313l0.212,74.432
				c0.016,5.248,3.741,7.369,8.336,4.713l95.647-55.217c4.539-2.619,8.216-8.956,8.257-14.185c-0.002,0.022-0.008,0.034-0.008,0.039
				c-0.236,0.134-4.931-64.14-4.931-64.14c-0.015-5.281-3.741-7.405-8.339-4.752C389.897,602.971,294.696,650.256,299.007,647.767"
            />
            <path
              fill="#CCCC11"
              d="M394.655,592.542l-95.648,55.225c-4.311,2.489,90.89-44.796,90.89-44.796
				c4.598-2.653,8.324-0.529,8.339,4.752c0,0,4.695,64.273,4.931,64.14c0-0.005,0.006-0.017,0.008-0.039
				c0.002-0.031,0.002-0.068,0.002-0.118l-0.21-74.426C402.951,592.031,399.225,589.905,394.655,592.542"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M299.207,712.519l7.25-5.18c0.193,4.528,1.063,7.202,2.61,8.021c1.547,0.82,3.688,0.44,6.422-1.139
					c2.015-1.163,3.756-2.631,5.22-4.402c1.463-1.77,2.471-3.607,3.024-5.516c0.552-1.906,0.828-4.6,0.828-8.079v-41.842l8.037-4.64
					v41.385c0,5.082-0.615,9.373-1.843,12.871c-1.229,3.5-3.177,6.751-5.841,9.752c-2.666,3.004-5.793,5.541-9.383,7.614
					c-5.331,3.077-9.412,3.9-12.242,2.47C300.456,722.402,299.096,718.631,299.207,712.519z"
              />
              <path
                fill="#FFFFFF"
                d="M342.996,684.957l7.581-5.039c0.359,2.832,1.194,4.842,2.506,6.031c1.312,1.189,3.348,1.588,6.11,1.195
					c2.761-0.394,5.868-1.587,9.321-3.58c3.066-1.771,5.772-3.788,8.12-6.056c2.347-2.267,4.094-4.524,5.24-6.774
					c1.146-2.25,1.72-4.313,1.72-6.191c0-1.906-0.553-3.25-1.657-4.036c-1.105-0.784-2.928-0.926-5.469-0.426
					c-1.629,0.306-5.234,1.399-10.813,3.28c-5.579,1.882-9.486,2.875-11.724,2.979c-2.9,0.156-5.061-0.481-6.483-1.911
					c-1.423-1.43-2.133-3.539-2.133-6.329c0-3.065,0.87-6.433,2.61-10.104c1.74-3.67,4.28-7.158,7.623-10.469
					c3.342-3.311,7.056-6.146,11.145-8.507c4.501-2.599,8.472-4.165,11.909-4.7c3.439-0.536,6.083,0.07,7.934,1.82
					c1.85,1.748,2.844,4.364,2.983,7.847l-7.706,5.028c-0.415-3.6-1.816-5.689-4.205-6.271c-2.389-0.581-5.917,0.476-10.585,3.17
					c-4.861,2.807-8.403,5.742-10.626,8.807c-2.223,3.065-3.334,5.854-3.334,8.367c0,2.183,0.787,3.523,2.361,4.022
					c1.546,0.516,5.585-0.372,12.118-2.667c6.531-2.293,11.012-3.588,13.443-3.887c3.535-0.412,6.145,0.145,7.83,1.672
					s2.527,3.92,2.527,7.178c0,3.232-0.925,6.811-2.775,10.738c-1.851,3.928-4.509,7.685-7.975,11.273
					c-3.466,3.59-7.368,6.637-11.703,9.14c-5.497,3.173-10.102,5.03-13.816,5.573c-3.715,0.543-6.628-0.185-8.741-2.182
					C344.218,691.951,343.106,688.955,342.996,684.957z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="vue-badge">
        <g enable-background="new    ">
          <defs>
            <polygon
              id="SVGID_3_"
              enable-background="new    "
              points="111.834,718.13 111.457,584.654 260.823,498.416 261.202,631.892 			
				"
            />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlink:href="#SVGID_3_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_4_)">
            <path
              fill="#42E8E0"
              d="M116.109,696.998c0.768,0.604,1.623,1.076,2.55,1.405L116.109,696.998 M232.69,514.66l-102.719,59.309
				c-10.235,5.908-18.489,20.199-18.455,31.962l0.228,79.903c0.012,4.404,1.193,7.836,3.208,10.073l6.423-11.527l-0.208-72.951
				c-0.033-11.76,8.221-26.091,18.455-31.997l102.691-59.286c0.041-0.029,0.079-0.047,0.12-0.066l2.57-7.104l1.828,1.049
				C243.418,511.388,238.34,511.396,232.69,514.66"
            />
            <path
              fill="#42E8E0"
              d="M245.003,512.975l-2.57,7.104c4.397-2.512,8.439-3.042,11.611-1.899l-7.213-4.156L245.003,512.975"
            />
            <path
              fill="#42E8E0"
              d="M121.375,684.38l-6.423,11.527l-0.19,0.345l1.347,0.746l2.55,1.405l6.559,3.606
				c-2.387-2.204-3.812-5.859-3.824-10.703L121.375,684.38"
            />
            <path
              fill="#CAFFF9"
              d="M139.651,590.489l102.694-59.296c4.935-2.846,8.936-0.563,8.951,5.105l0.227,79.871
				c0,0.057-0.001,0.102-0.001,0.13c-0.042,5.643-3.992,12.45-8.891,15.275l-102.693,59.287c-4.909,2.841-8.908,0.56-8.924-5.118
				l-0.226-79.867C130.77,600.204,134.746,593.321,139.651,590.489 M242.313,520.146l-102.691,59.286
				c-10.233,5.906-18.488,20.237-18.455,31.997l0.208,72.951l0.019,6.927c0.012,4.844,1.437,8.499,3.824,10.703
				c3.395,3.145,8.741,3.35,14.749-0.124l102.694-59.284c10.234-5.912,18.514-20.224,18.482-31.984l-0.227-79.873
				c-0.02-6.652-2.696-11.059-6.873-12.564c-3.172-1.143-7.213-0.612-11.611,1.899C242.392,520.099,242.354,520.116,242.313,520.146
				"
            />
            <path
              fill="#42E8E0"
              d="M139.651,590.489c-4.905,2.832-8.881,9.715-8.864,15.388l0.226,79.867
				c0.016,5.678,4.015,7.959,8.924,5.118l102.693-59.287c4.899-2.825,8.849-9.633,8.891-15.275
				c-0.002,0.022-0.004,0.042-0.009,0.042c-0.256,0.148-5.322-68.847-5.322-68.847c-0.017-5.634-4.017-7.92-8.951-5.07
				C237.239,542.425,135.024,593.158,139.651,590.489"
            />
            <path
              fill="#1FC1B5"
              d="M242.345,531.193l-102.694,59.296c-4.626,2.669,97.588-48.064,97.588-48.064
				c4.934-2.85,8.935-0.563,8.951,5.07c0,0,5.065,68.995,5.322,68.847c0.004,0,0.007-0.02,0.009-0.042
				c0-0.028,0.001-0.073,0.001-0.13l-0.227-79.871C251.28,530.63,247.279,528.348,242.345,531.193"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M166.71,607.564l4.917-2.505l-14.16,46.498l-4.514,2.3l-14.08-32.109l4.836-2.464l9.029,20.818
					c1.039,2.391,1.863,4.81,2.472,7.258c0.645-3.23,1.486-6.552,2.526-9.966L166.71,607.564z"
              />
              <path
                fill="#FFFFFF"
                d="M206.692,587.193v25.419c0,4.479-1.353,8.688-4.057,12.627c-2.706,3.94-6.422,7.114-11.151,9.523
					s-8.388,2.984-10.976,1.724c-2.589-1.261-3.883-4.148-3.883-8.662v-25.312l4.567-2.327v25.634c0,3.278,0.896,5.339,2.687,6.181
					c1.791,0.844,4.425,0.38,7.9-1.391c3.313-1.688,5.866-3.872,7.658-6.549c1.791-2.678,2.687-5.664,2.687-8.96v-25.58
					L206.692,587.193z"
              />
              <path
                fill="#FFFFFF"
                d="M238.989,610.021l-21.898,11.157v-39.283l21.898-11.157v4.058l-17.331,8.83v12.655l16.283-8.296v4.029
					l-16.283,8.297v14.456l17.331-8.83V610.021z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="html-badge">
        <g enable-background="new    ">
          <defs>
            <polygon
              id="SVGID_5_"
              enable-background="new    "
              points="328.799,573.916 328.576,495.611 416.188,445.028 416.411,523.332 
							"
            />
          </defs>
          <clipPath id="SVGID_6_">
            <use xlink:href="#SVGID_5_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_6_)">
            <path
              fill="#EFD9A5"
              d="M331.321,561.525c0.444,0.351,0.938,0.622,1.473,0.813L331.321,561.525 M399.683,454.56l-60.245,34.781
				c-6.003,3.466-10.846,11.853-10.825,18.752l0.131,46.857c0.008,2.596,0.702,4.607,1.884,5.926l3.767-6.768l-0.122-42.809
				c-0.019-6.877,4.824-15.288,10.826-18.751l60.244-34.782c0.023-0.014,0.044-0.024,0.067-0.038l1.511-4.186l0.955,0.551
				C405.877,452.631,402.944,452.675,399.683,454.56"
            />
            <path
              fill="#EFD4A5"
              d="M413.699,457.441c0.009,0.009,0.019,0.014,0.028,0.021l0.006-0.002L413.699,457.441 M406.922,453.543
				l-1.511,4.186c2.64-1.505,5.057-1.8,6.935-1.064l-4.469-2.57L406.922,453.543"
            />
            <path
              fill="#EFD4A5"
              d="M334.396,554.108l-3.767,6.768l-0.113,0.206l0.806,0.443l1.473,0.813l3.857,2.125
				c-1.401-1.297-2.236-3.438-2.243-6.285L334.396,554.108"
            />
            <path
              fill="#F9EDDE"
              d="M345.118,499.035l60.245-34.783c2.896-1.673,5.243-0.335,5.251,2.973l0.133,46.878
				c0.001,0.031,0,0.054-0.002,0.074c-0.023,3.31-2.342,7.301-5.214,8.964l-60.246,34.781c-2.896,1.672-5.243,0.333-5.251-2.994
				l-0.134-46.877C339.892,504.742,342.224,500.704,345.118,499.035 M405.344,457.767L345.1,492.549
				c-6.002,3.463-10.845,11.874-10.826,18.751l0.122,42.809l0.012,4.069c0.007,2.848,0.842,4.988,2.243,6.285
				c1.991,1.841,5.126,1.961,8.655-0.074l60.243-34.781c6.003-3.47,10.845-11.856,10.826-18.754l-0.133-46.877
				c-0.009-3.02-0.952-5.252-2.515-6.514c-0.009-0.008-0.019-0.013-0.028-0.021c-0.411-0.328-0.864-0.589-1.353-0.777
				c-1.878-0.735-4.295-0.44-6.935,1.064C405.388,457.742,405.367,457.753,405.344,457.767"
            />
            <path
              fill="#EFD7A5"
              d="M345.118,499.035c-2.894,1.669-5.226,5.707-5.217,9.016l0.134,46.877
				c0.008,3.327,2.355,4.666,5.251,2.994l60.246-34.781c2.872-1.663,5.19-5.654,5.214-8.964c0,0.013-0.001,0.024-0.004,0.024
				c-0.151,0.087-3.123-40.39-3.123-40.39c-0.01-3.328-2.355-4.663-5.251-2.993C402.367,470.818,342.403,500.6,345.118,499.035"
            />
            <path
              fill="#C18E44"
              d="M405.363,464.252l-60.245,34.783c-2.715,1.564,57.249-28.217,57.249-28.217
				c2.896-1.67,5.241-0.335,5.251,2.993c0,0,2.972,40.477,3.123,40.39c0.002,0,0.004-0.012,0.004-0.024
				c0.002-0.021,0.003-0.043,0.002-0.074l-0.133-46.878C410.605,463.917,408.258,462.579,405.363,464.252"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M346.13,538.345v-16.399l2.17-1.253v6.734l8.525-4.922v-6.734l2.17-1.253v16.399l-2.17,1.253v-7.73
					l-8.525,4.922v7.73L346.13,538.345z"
              />
              <path
                fill="#FFFFFF"
                d="M366.782,526.422v-14.465l-5.403,3.119v-1.935l12.999-7.505v1.935l-5.425,3.132v14.465L366.782,526.422z
					"
              />
              <path
                fill="#FFFFFF"
                d="M376.537,520.789v-16.398l3.266-1.887l3.882,9.371c0.358,0.875,0.619,1.533,0.783,1.975
					c0.186-0.703,0.477-1.748,0.873-3.133l3.926-13.676l2.92-1.687v16.399l-2.092,1.208v-13.727l-4.766,16.478l-1.958,1.131
					l-4.743-11.223v13.961L376.537,520.789z"
              />
              <path
                fill="#FFFFFF"
                d="M395.599,509.784v-16.399l2.17-1.253v14.464l8.077-4.663v1.936L395.599,509.784z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="pc">
        <g enable-background="new    ">
          <defs>
            <polygon
              id="SVGID_7_"
              enable-background="new    "
              points="750.153,480.972 751.291,79.717 1182.854,328.88 1181.718,730.133 
							"
            />
          </defs>
          <clipPath id="SVGID_8_">
            <use xlink:href="#SVGID_7_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_8_)">
            <path
              fill="#A5A5F2"
              d="M1042.367,274.953l49.078,28.295l0.04-14.179l-49.08-28.335L852.429,151.05
				c-5.825-3.364-16.354,6.549-16.956,7.122c1.388-0.867,3.339-0.78,5.498,0.466l201.396,116.277l0.038-14.181"
            />
            <path
              fill="#A5A5F2"
              d="M1175.242,337.427l-83.757-48.358l-0.04,14.179l70.765,40.857c4.2,2.424,7.575,8.286,7.561,13.098
				l-0.639,225.389l-0.073,26.14c-0.01,3.1-1.421,5.012-3.546,5.33c1.492-0.102,16.536-1.244,16.548-5.816l0.73-257.743
				C1182.806,345.692,1179.431,339.845,1175.242,337.427"
            />
            <path
              fill="#0F054C"
              d="M1162.21,344.106l-70.765-40.857l-0.046,15.243l70.077,40.458l-0.578,203.627L841.039,377.903
				l0.578-203.625l200.706,115.877l0.044-15.24L840.971,158.638c-2.159-1.246-4.11-1.333-5.498-0.466
				c-1.303,0.815-2.107,2.472-2.112,4.803l-0.641,225.391l336.412,194.227l0.639-225.389
				C1169.785,352.392,1166.41,346.53,1162.21,344.106"
            />
            <polyline
              fill="#6464DD"
              points="1049.761,548.586 1041.291,543.697 1046.666,585.754 1046.309,585.971 995.719,616.526 
				907.884,565.813 907.868,572.025 999.444,624.898 1054.32,588.642 1049.761,548.586 			"
            />
            <polyline
              fill="#FFFFFF"
              points="1046.309,585.971 960.086,536.191 907.884,565.813 995.719,616.526 1046.309,585.971 			"
            />
            <polyline
              fill="#A5A5F2"
              points="1041.291,543.697 969.44,502.216 960.457,535.98 960.075,536.185 960.086,536.191 
				1046.309,585.971 1046.666,585.754 1041.291,543.697 			"
            />
            <path
              fill="#FFFFFF"
              d="M1169.132,582.593L832.72,388.366l-0.073,26.138c-0.012,4.813,3.373,10.681,7.562,13.1l129.232,74.612
				l71.851,41.481l8.47,4.889l111.687,64.483c1.495,0.864,2.89,1.173,4.065,0.993c2.125-0.318,3.536-2.23,3.546-5.33
				L1169.132,582.593"
            />
            <path
              fill="#1C157A"
              d="M852.511,374.279l0.023-7.926l6.894,3.981l-0.021,7.928L852.511,374.279 M867.152,382.732l0.022-7.925
				l26.65,15.387l-0.021,7.924L867.152,382.732 M852.545,361.753l0.022-7.924l6.898,3.982l-0.023,7.924L852.545,361.753
				 M867.188,370.208l0.022-7.925l44.422,25.649l-0.023,7.924L867.188,370.208 M852.581,349.244l0.021-7.925l6.896,3.982
				l-0.021,7.925L852.581,349.244 M879.688,364.894l0.021-7.924l103.389,59.692l-0.02,7.924L879.688,364.894 M852.617,336.734
				l0.021-7.938l6.896,3.98l-0.02,7.939L852.617,336.734 M867.261,345.188l0.022-7.938l82.612,47.697l-0.022,7.94L867.261,345.188
				 M852.652,324.21l0.021-7.925l6.896,3.981l-0.022,7.925L852.652,324.21 M879.759,339.86l0.022-7.924l53.314,30.78l-0.021,7.926
				L879.759,339.86 M852.71,303.775l6.897,3.981l-0.023,7.925l-6.896-3.981L852.71,303.775 M867.332,320.154l0.021-7.924
				l26.65,15.386l-0.02,7.925L867.332,320.154 M852.723,299.175l0.021-7.924l6.899,3.982l-0.023,7.924L852.723,299.175
				 M867.366,307.631l0.022-7.925l44.422,25.646l-0.023,7.925L867.366,307.631 M852.76,286.666l0.021-7.925l6.895,3.982
				l-0.021,7.925L852.76,286.666 M879.866,302.315l0.023-7.924l103.387,59.692l-0.022,7.925L879.866,302.315 M852.793,274.142
				l0.022-7.924l6.898,3.981l-0.023,7.926L852.793,274.142 M867.437,282.596l0.022-7.925l82.614,47.697l-0.023,7.924
				L867.437,282.596 M852.829,261.632l0.022-7.925l6.896,3.981l-0.022,7.925L852.829,261.632 M879.937,277.282l0.021-7.924
				l53.315,30.781l-0.021,7.925L879.937,277.282 M852.865,249.108l0.021-7.924l6.898,3.981l-0.023,7.925L852.865,249.108
				 M867.509,257.562l0.023-7.924l132.698,76.613l-0.022,7.926L867.509,257.562 M1021.197,345.232l0.054-18.893l16.452,9.5
				l-0.053,18.892L1021.197,345.232 M852.9,236.597l0.022-7.924l6.896,3.982l-0.021,7.924L852.9,236.597 M867.543,245.053
				l0.021-7.926l82.613,47.696l-0.022,7.925L867.543,245.053 M852.937,224.088l0.023-7.939l6.895,3.982l-0.022,7.939
				L852.937,224.088 M880.043,239.737l0.022-7.938l88.855,51.301l-0.023,7.94L880.043,239.737 M852.971,211.564l0.022-7.925
				l6.898,3.982l-0.023,7.926L852.971,211.564 M867.614,220.018l0.022-7.925l93.007,53.699l-0.022,7.924L867.614,220.018
				 M998.225,298.446l0.065-23.153l20.135,11.625l-0.063,23.152L998.225,298.446 M853.007,199.054l0.021-7.924l6.896,3.982
				l-0.021,7.924L853.007,199.054 M867.65,207.508l0.023-7.925l72.23,41.703l-0.023,7.924L867.65,207.508 M1161.477,358.949
				l-70.077-40.458l-49.076-28.336L841.617,174.278l-0.578,203.625l319.859,184.673L1161.477,358.949"
            />
            <polyline
              fill="#FF2C9C"
              points="859.924,195.112 853.028,191.129 853.007,199.054 859.902,203.036 859.924,195.112 			"
            />
            <polyline
              fill="#FFFFFF"
              points="939.904,241.286 867.674,199.583 867.65,207.508 939.881,249.21 939.904,241.286 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.892,207.621 852.993,203.639 852.971,211.564 859.868,215.546 859.892,207.621 			"
            />
            <polyline
              fill="#42E8E0"
              points="960.644,265.792 867.637,212.093 867.614,220.018 960.621,273.716 960.644,265.792 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.854,220.131 852.96,216.149 852.937,224.088 859.832,228.07 859.854,220.131 			"
            />
            <polyline
              fill="#FFFFFF"
              points="968.921,283.1 880.065,231.799 880.043,239.737 968.897,291.04 968.921,283.1 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.818,232.655 852.923,228.672 852.9,236.597 859.797,240.579 859.818,232.655 			"
            />
            <polyline
              fill="#42E8E0"
              points="950.178,284.823 867.564,237.127 867.543,245.053 950.155,292.748 950.178,284.823 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.785,245.165 852.887,241.184 852.865,249.108 859.762,253.09 859.785,245.165 			"
            />
            <polyline
              fill="#FFFFFF"
              points="1000.23,326.251 867.532,249.638 867.509,257.562 1000.208,334.177 1000.23,326.251 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.748,257.688 852.852,253.707 852.829,261.632 859.726,265.613 859.748,257.688 			"
            />
            <polyline
              fill="#42E8E0"
              points="933.273,300.138 879.958,269.357 879.937,277.282 933.252,308.063 933.273,300.138 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.714,270.199 852.815,266.218 852.793,274.142 859.69,278.125 859.714,270.199 			"
            />
            <polyline
              fill="#42E8E0"
              points="950.073,322.369 867.459,274.671 867.437,282.596 950.05,330.293 950.073,322.369 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.676,282.723 852.781,278.741 852.76,286.666 859.654,290.648 859.676,282.723 			"
            />
            <polyline
              fill="#FFFFFF"
              points="983.276,354.083 879.89,294.391 879.866,302.315 983.254,362.008 983.276,354.083 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.644,295.233 852.744,291.25 852.723,299.175 859.62,303.157 859.644,295.233 			"
            />
            <polyline
              fill="#42E8E0"
              points="911.811,325.353 867.389,299.706 867.366,307.631 911.787,333.277 911.811,325.353 			"
            />
            <polyline
              fill="#FFFFFF"
              points="894.003,327.616 867.353,312.23 867.332,320.154 893.983,335.541 894.003,327.616 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.607,307.757 852.71,303.775 852.688,311.701 859.584,315.682 859.607,307.757 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.57,320.267 852.674,316.285 852.652,324.21 859.548,328.191 859.57,320.267 			"
            />
            <polyline
              fill="#FFFFFF"
              points="933.096,362.716 879.781,331.936 879.759,339.86 933.074,370.642 933.096,362.716 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.534,332.776 852.639,328.796 852.617,336.734 859.515,340.716 859.534,332.776 			"
            />
            <polyline
              fill="#42E8E0"
              points="949.896,384.946 867.283,337.25 867.261,345.188 949.873,392.887 949.896,384.946 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.499,345.301 852.603,341.319 852.581,349.244 859.478,353.226 859.499,345.301 			"
            />
            <polyline
              fill="#FFFFFF"
              points="983.098,416.661 879.709,356.969 879.688,364.894 983.078,424.585 983.098,416.661 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.466,357.811 852.567,353.829 852.545,361.753 859.442,365.735 859.466,357.811 			"
            />
            <polyline
              fill="#42E8E0"
              points="911.633,387.932 867.211,362.283 867.188,370.208 911.609,395.855 911.633,387.932 			"
            />
            <polyline
              fill="#FF2C9C"
              points="859.428,370.333 852.534,366.353 852.511,374.279 859.406,378.261 859.428,370.333 			"
            />
            <polyline
              fill="#42E8E0"
              points="893.825,390.194 867.175,374.808 867.152,382.732 893.804,398.118 893.825,390.194 			"
            />
            <polyline
              fill="#FFFFFF"
              points="821.124,209.847 801.765,198.669 801.702,220.916 821.062,232.094 821.124,209.847 			"
            />
            <polyline
              fill="#FFFFFF"
              points="1018.425,286.918 998.29,275.293 998.225,298.446 1018.361,310.071 1018.425,286.918 			"
            />
            <polyline
              fill="#FF2C9C"
              points="1037.703,335.839 1021.251,326.339 1021.197,345.232 1037.65,354.731 1037.703,335.839 			"
            />
          </g>
        </g>
        <polygon
          fill="#0F054C"
          points="1091.445,303.249 1042.367,274.915 1042.323,290.155 1091.399,318.491 	"
        />
      </g>
      <g id="tag-01">
        <g enable-background="new    ">
          <defs>
            <polygon
              id="SVGID_9_"
              enable-background="new    "
              points="1147.195,284.757 1147.365,225.787 1213.326,263.871 
				1213.161,322.841 			"
            />
          </defs>
          <clipPath id="SVGID_10_">
            <use xlink:href="#SVGID_9_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_10_)">
            <path
              fill="#6464DD"
              d="M1211.291,313.482l-1.149,0.636C1210.562,313.971,1210.947,313.758,1211.291,313.482 M1205.148,259.15
				l-45.357-26.188c-2.498-1.442-4.741-1.45-6.251-0.291l0.8-0.46l1.141,3.154c0.016,0.009,0.032,0.018,0.047,0.027l45.358,26.187
				c4.521,2.609,8.166,8.924,8.151,14.117l-0.092,32.224l2.839,5.096c0.891-0.991,1.409-2.503,1.416-4.456l0.099-35.293
				C1213.314,268.075,1209.669,261.759,1205.148,259.15"
            />
            <path
              fill="#6464DD"
              d="M1154.34,232.211l-0.8,0.46l-3.259,1.876c1.412-0.539,3.224-0.31,5.199,0.818L1154.34,232.211"
            />
            <path
              fill="#6464DD"
              d="M1208.945,307.92l-0.009,3.071c-0.005,2.142-0.636,3.758-1.694,4.731l2.899-1.604l1.149-0.636
				l0.577-0.319l-0.084-0.147L1208.945,307.92"
            />
            <path
              fill="#A5A5F2"
              d="M1151.561,242.512c0.006-2.504,1.772-3.511,3.954-2.253l45.358,26.188c2.18,1.259,3.936,4.3,3.927,6.804
				l-0.1,35.294c-0.006,2.49-1.772,3.497-3.953,2.239l-45.359-26.187c-2.164-1.25-3.908-4.257-3.927-6.737c0-0.012,0-0.029,0-0.053
				L1151.561,242.512 M1200.886,261.579l-45.358-26.187c-0.015-0.009-0.031-0.018-0.047-0.027c-1.976-1.128-3.787-1.357-5.199-0.818
				c-1.801,0.686-2.949,2.619-2.957,5.52l-0.101,35.293c-0.015,5.179,3.631,11.509,8.151,14.118l45.358,26.188
				c2.65,1.53,5.009,1.438,6.509,0.056c1.059-0.973,1.689-2.589,1.694-4.731l0.009-3.071l0.092-32.224
				C1209.052,270.503,1205.407,264.188,1200.886,261.579"
            />
            <path
              fill="#6464DD"
              d="M1175.249,284.369c-1.341-0.774-2.276-1.513-2.805-2.215c-0.53-0.702-0.786-1.53-0.783-2.493l0.009-2.972
				c0.001-0.552-0.182-1.084-0.539-1.572c-0.356-0.49-0.873-0.944-1.564-1.342l0.008-2.251c0.69,0.399,1.208,0.556,1.565,0.493
				c0.358-0.077,0.545-0.367,0.545-0.876v-0.084l0.01-3c0.002-0.962,0.264-1.478,0.792-1.568c0.531-0.091,1.47,0.251,2.811,1.027
				l-0.005,2.122c-0.542-0.299-0.925-0.391-1.121-0.279c-0.211,0.091-0.312,0.359-0.313,0.784l-0.009,2.816
				c-0.037,1.167-0.681,1.491-1.926,0.985v0.113c1.279,0.966,1.92,2.001,1.916,3.118v0.086l-0.008,2.816
				c-0.001,0.423,0.094,0.806,0.293,1.146c0.209,0.333,0.576,0.674,1.131,1.009L1175.249,284.369 M1180.953,287.661l0.005-2.137
				c0.541,0.299,0.926,0.405,1.121,0.307c0.211-0.091,0.308-0.359,0.313-0.813l0.009-2.815v-0.114c0-0.538,0.174-0.877,0.496-1.031
				c0.32-0.141,0.801-0.089,1.428,0.159l0.002-0.113c-1.244-0.931-1.881-1.993-1.916-3.203l0.009-2.816
				c0.001-0.439-0.097-0.821-0.293-1.147c-0.21-0.333-0.577-0.674-1.132-1.008l0.005-2.123c0.926,0.534,1.638,1.03,2.156,1.5
				c0.515,0.468,0.884,0.937,1.104,1.433c0.22,0.481,0.33,1.083,0.329,1.762l-0.009,3v0.1c-0.001,0.524,0.169,1.019,0.502,1.48
				c0.341,0.468,0.875,0.901,1.6,1.32l-0.006,2.25c-0.678-0.391-1.208-0.555-1.565-0.507c-0.358,0.049-0.545,0.38-0.546,0.989
				l-0.009,2.972c-0.002,0.693-0.114,1.167-0.337,1.394c-0.222,0.239-0.594,0.28-1.112,0.152
				C1182.592,288.522,1181.875,288.195,1180.953,287.661 M1200.873,266.447c2.044,1.182-43.104-21.228-43.104-21.228
				c-2.179-1.258-3.946-0.251-3.953,2.239c0,0-2.239,30.487-2.351,30.422c0,0-0.004-0.007-0.004-0.021
				c0.019,2.48,1.763,5.487,3.927,6.737l45.359,26.187c2.181,1.258,3.947,0.251,3.953-2.239l0.1-35.294
				C1204.809,270.747,1203.053,267.706,1200.873,266.447"
            />
            <path
              fill="#FFFFFF"
              d="M1175.297,267.515c-1.341-0.775-2.279-1.118-2.811-1.027c-0.528,0.09-0.79,0.606-0.792,1.568l-0.01,3
				v0.084c0,0.509-0.187,0.799-0.545,0.876c-0.357,0.063-0.875-0.094-1.565-0.493l-0.008,2.251c0.691,0.398,1.208,0.852,1.564,1.342
				c0.357,0.488,0.54,1.02,0.539,1.572l-0.009,2.972c-0.003,0.962,0.253,1.791,0.783,2.493c0.528,0.703,1.464,1.441,2.805,2.215
				l0.007-2.136c-0.555-0.335-0.922-0.676-1.131-1.009c-0.199-0.34-0.294-0.723-0.293-1.146l0.008-2.816v-0.086
				c0.004-1.117-0.637-2.152-1.916-3.118v-0.113c1.245,0.505,1.889,0.182,1.926-0.985l0.009-2.816
				c0.001-0.425,0.102-0.693,0.313-0.784c0.196-0.112,0.579-0.02,1.121,0.279L1175.297,267.515"
            />
            <path
              fill="#FFFFFF"
              d="M1180.999,270.807l-0.005,2.123c0.555,0.334,0.922,0.675,1.132,1.008
				c0.196,0.326,0.294,0.708,0.293,1.147l-0.009,2.816c0.035,1.21,0.672,2.272,1.916,3.203l-0.002,0.113
				c-0.627-0.249-1.107-0.3-1.428-0.159c-0.322,0.154-0.496,0.493-0.496,1.031v0.114l-0.009,2.815
				c-0.005,0.453-0.102,0.721-0.313,0.813c-0.195,0.099-0.58-0.008-1.121-0.307l-0.005,2.137c0.922,0.534,1.639,0.861,2.153,0.99
				c0.519,0.128,0.891,0.087,1.112-0.152c0.223-0.226,0.335-0.7,0.337-1.394l0.009-2.972c0.001-0.609,0.188-0.94,0.546-0.989
				c0.357-0.048,0.888,0.116,1.565,0.507l0.006-2.25c-0.725-0.419-1.259-0.852-1.6-1.32c-0.333-0.461-0.503-0.956-0.502-1.48v-0.1
				l0.009-3c0.001-0.679-0.109-1.281-0.329-1.762c-0.221-0.497-0.59-0.965-1.104-1.433
				C1182.637,271.837,1181.925,271.34,1180.999,270.807"
            />
            <path
              fill="#4343C4"
              d="M1200.873,266.447l-45.358-26.188c-2.182-1.258-3.948-0.251-3.954,2.253l-0.1,35.294
				c0,0.024,0,0.041,0,0.053c0,0.013,0.004,0.02,0.004,0.021c0.111,0.065,2.351-30.422,2.351-30.422
				c0.007-2.49,1.774-3.497,3.953-2.239C1157.769,245.219,1202.917,267.628,1200.873,266.447"
            />
          </g>
        </g>
      </g>
      <g id="css-badge">
        <g enable-background="new    ">
          <defs>
            <polygon
              id="SVGID_11_"
              enable-background="new    "
              points="1034.213,201.487 1034.424,126 1118.891,174.766 1118.678,250.253 
							"
            />
          </defs>
          <clipPath id="SVGID_12_">
            <use xlink:href="#SVGID_11_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_12_)">
            <path
              fill="#A5C0EF"
              d="M1116.263,238.294l-1.431,0.791C1115.353,238.901,1115.831,238.634,1116.263,238.294 M1108.412,168.716
				l-58.08-33.531c-3.163-1.826-6.005-1.849-7.932-0.402l0.97-0.558l1.454,4.027c0.021,0.012,0.039,0.023,0.064,0.036l58.075,33.531
				c5.775,3.334,10.449,11.43,10.428,18.083l-0.113,41.253l3.639,6.522c1.135-1.269,1.806-3.207,1.813-5.705l0.128-45.183
				C1118.876,180.155,1114.202,172.059,1108.412,168.716"
            />
            <path
              fill="#A5BBEF"
              d="M1043.37,134.226l-0.97,0.558l-4.169,2.398c1.801-0.657,4.095-0.355,6.593,1.071L1043.37,134.226"
            />
            <path
              fill="#A5C0EF"
              d="M1113.278,231.156l-0.011,3.93c-0.01,2.763-0.83,4.84-2.201,6.083l3.766-2.084l1.431-0.791l0.762-0.422
				l-0.107-0.194L1113.278,231.156"
            />
            <path
              fill="#DEE8F9"
              d="M1039.82,147.424c0.006-3.201,2.265-4.505,5.05-2.896l58.077,33.53c2.785,1.608,5.026,5.51,5.02,8.711
				l-0.128,45.183c-0.011,3.182-2.268,4.486-5.052,2.878l-58.077-33.531c-2.767-1.597-4.998-5.456-5.021-8.625
				c0-0.016,0-0.038,0-0.067L1039.82,147.424 M1102.964,171.82l-58.075-33.531c-0.025-0.012-0.043-0.024-0.064-0.036
				c-2.498-1.427-4.792-1.728-6.593-1.071c-2.345,0.855-3.847,3.346-3.859,7.098l-0.125,45.184
				c-0.021,6.637,4.652,14.731,10.445,18.074l58.076,33.53c3.371,1.946,6.375,1.842,8.298,0.102
				c1.371-1.243,2.191-3.321,2.201-6.083l0.011-3.93l0.113-41.253C1113.413,183.25,1108.739,175.154,1102.964,171.82"
            />
            <path
              fill="#A5BDEF"
              d="M1102.947,178.058c2.611,1.508-55.195-27.178-55.195-27.178c-2.788-1.606-5.045-0.32-5.052,2.88
				c0,0-2.861,39.024-3.006,38.939c0-0.001-0.003-0.009-0.005-0.026c0.022,3.17,2.254,7.029,5.021,8.625l58.077,33.531
				c2.784,1.608,5.041,0.303,5.052-2.878l0.128-45.183C1107.974,183.567,1105.732,179.666,1102.947,178.058"
            />
            <path
              fill="#4343C4"
              d="M1102.947,178.058l-58.077-33.53c-2.785-1.608-5.044-0.304-5.05,2.896l-0.131,45.183
				c0,0.03,0,0.052,0,0.067c0.002,0.017,0.005,0.025,0.005,0.026c0.145,0.085,3.006-38.939,3.006-38.939
				c0.007-3.201,2.264-4.486,5.052-2.88C1047.752,150.88,1105.559,179.566,1102.947,178.058"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M1061.334,185.662l2.699,2.058c-0.565,1.93-1.584,3.102-3.055,3.517s-3.269,0.081-5.394-1.001
					c-2.199-1.12-3.988-2.479-5.365-4.077c-1.379-1.598-2.427-3.429-3.146-5.493c-0.719-2.064-1.078-4.071-1.078-6.02
					c0-2.125,0.405-3.772,1.217-4.941c0.813-1.168,1.968-1.782,3.467-1.838c1.499-0.058,3.147,0.372,4.948,1.289
					c2.041,1.04,3.758,2.435,5.149,4.183c1.393,1.749,2.362,3.704,2.909,5.866l-2.658-0.728c-0.474-1.726-1.16-3.157-2.06-4.293
					c-0.9-1.136-2.032-2.052-3.397-2.747c-1.567-0.799-2.878-1.091-3.932-0.876c-1.053,0.215-1.793,0.847-2.22,1.896
					s-0.641,2.247-0.641,3.592c0,1.735,0.253,3.378,0.759,4.931c0.506,1.552,1.292,2.92,2.359,4.104
					c1.067,1.184,2.222,2.093,3.466,2.727c1.513,0.771,2.793,0.986,3.842,0.649C1060.253,188.123,1060.962,187.189,1061.334,185.662
					z"
              />
              <path
                fill="#FFFFFF"
                d="M1066.442,188.863l2.547,1.075c0.121,1.083,0.4,2.063,0.842,2.941c0.44,0.879,1.125,1.756,2.053,2.633
					c0.929,0.876,1.972,1.61,3.133,2.201c1.029,0.524,1.938,0.835,2.728,0.931c0.788,0.095,1.376-0.025,1.761-0.363
					c0.385-0.337,0.577-0.822,0.577-1.453c0-0.64-0.186-1.293-0.556-1.96c-0.372-0.667-0.984-1.381-1.838-2.141
					c-0.547-0.492-1.759-1.44-3.633-2.846s-3.188-2.499-3.939-3.281c-0.974-1.007-1.7-2.01-2.179-3.01
					c-0.478-1-0.716-1.968-0.716-2.905c0-1.03,0.292-1.844,0.876-2.441c0.585-0.598,1.438-0.842,2.562-0.734
					c1.123,0.108,2.371,0.512,3.744,1.212c1.513,0.771,2.847,1.694,4.002,2.77c1.155,1.076,2.044,2.246,2.665,3.509
					c0.622,1.264,0.957,2.506,1.003,3.726l-2.589-1.124c-0.14-1.361-0.61-2.576-1.413-3.643c-0.803-1.068-1.988-2.002-3.557-2.801
					c-1.633-0.832-2.823-1.139-3.569-0.921c-0.748,0.218-1.121,0.749-1.121,1.593c0,0.733,0.265,1.471,0.794,2.214
					c0.52,0.738,1.877,1.915,4.071,3.529s3.699,2.815,4.517,3.603c1.188,1.153,2.063,2.293,2.63,3.421
					c0.566,1.128,0.85,2.24,0.85,3.335c0,1.085-0.312,1.95-0.933,2.594c-0.622,0.644-1.515,0.936-2.68,0.876
					c-1.165-0.061-2.475-0.461-3.932-1.203c-1.848-0.941-3.395-1.999-4.643-3.173s-2.228-2.483-2.938-3.926
					C1066.853,191.659,1066.479,190.246,1066.442,188.863z"
              />
              <path
                fill="#FFFFFF"
                d="M1085.456,198.552l2.547,1.075c0.121,1.082,0.401,2.062,0.842,2.941c0.44,0.878,1.126,1.756,2.053,2.633
					c0.929,0.876,1.972,1.609,3.133,2.201c1.029,0.524,1.938,0.834,2.728,0.931c0.789,0.095,1.376-0.025,1.762-0.362
					c0.384-0.338,0.576-0.822,0.576-1.453c0-0.64-0.185-1.294-0.556-1.961c-0.371-0.667-0.984-1.38-1.838-2.14
					c-0.547-0.492-1.758-1.441-3.633-2.847c-1.874-1.405-3.187-2.498-3.938-3.281c-0.975-1.006-1.701-2.01-2.179-3.01
					c-0.479-1-0.717-1.967-0.717-2.905c0-1.03,0.292-1.845,0.877-2.442c0.584-0.597,1.438-0.842,2.562-0.733
					c1.122,0.107,2.37,0.511,3.744,1.211c1.512,0.771,2.846,1.694,4.001,2.77c1.156,1.076,2.044,2.245,2.666,3.508
					s0.956,2.505,1.002,3.726l-2.589-1.124c-0.139-1.36-0.61-2.576-1.413-3.644c-0.803-1.067-1.988-2.001-3.556-2.8
					c-1.634-0.832-2.824-1.14-3.57-0.921c-0.747,0.217-1.121,0.749-1.121,1.593c0,0.733,0.265,1.471,0.794,2.214
					c0.52,0.738,1.877,1.915,4.071,3.529s3.7,2.815,4.517,3.603c1.188,1.153,2.064,2.293,2.631,3.421
					c0.565,1.128,0.849,2.24,0.849,3.334c0,1.086-0.312,1.95-0.933,2.594c-0.622,0.644-1.515,0.936-2.679,0.875
					c-1.165-0.06-2.476-0.461-3.933-1.203c-1.847-0.941-3.395-1.999-4.642-3.172c-1.249-1.175-2.228-2.483-2.938-3.926
					C1085.867,201.348,1085.492,199.934,1085.456,198.552z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="tab">
        <g enable-background="new    ">
          <defs>
            <polygon
              id="SVGID_13_"
              enable-background="new    "
              points="456.54,734.951 455.905,510.96 598.015,428.912 598.65,652.903 			
				"
            />
          </defs>
          <clipPath id="SVGID_14_">
            <use xlink:href="#SVGID_13_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_14_)">
            <path
              fill="#2A2A9B"
              d="M461.33,636.023c-0.32,0.184-0.58-0.176-0.582-0.799l-0.051-17.915c-0.001-0.623,0.257-1.282,0.576-1.466
				c0.32-0.186,0.593,0.169,0.594,0.79l0.051,17.917C461.92,635.172,461.649,635.84,461.33,636.023 M461.268,613.877
				c-0.32,0.184-0.581-0.176-0.582-0.784l-0.173-61.162c-0.002-0.608,0.255-1.268,0.576-1.452c0.32-0.186,0.592,0.167,0.594,0.775
				l0.174,61.163C461.857,613.025,461.588,613.693,461.268,613.877 M583.704,437.175l-123.463,71.297
				c-2.39,1.379-4.329,4.738-4.322,7.468l0.575,198.3c0.008,2.731,8.848,14.07,11.225,12.699c-0.74-0.472-1.2-1.416-1.204-2.736
				l-0.048-16.768l-0.526-181.517c-0.008-2.745,1.932-6.105,4.309-7.475l123.475-71.306c1.434-0.828,2.711-0.745,3.503,0.049
				C597.188,444.428,586.078,435.805,583.704,437.175"
            />
            <path
              fill="#6464DD"
              d="M474.569,697.949c-2.168,1.252-3.947,0.238-3.954-2.237l-0.484-166.359
				c-0.007-2.49,1.76-5.539,3.928-6.79l115.68-66.801c2.168-1.253,3.947-0.238,3.954,2.252l0.484,166.344
				c0.007,2.491-1.761,5.554-3.927,6.805L474.569,697.949 M593.724,447.138l-123.475,71.306c-2.377,1.369-4.317,4.729-4.309,7.475
				l0.526,181.517l132.122-76.283l-0.526-181.527c-0.004-1.084-0.313-1.914-0.834-2.438
				C596.436,446.393,595.158,446.31,593.724,447.138"
            />
            <path
              fill="#0F054C"
              d="M501.13,672.551l-0.009-3.469l24.114-13.92l0.009,3.467L501.13,672.551 M527.35,657.414l-0.009-3.469
				l43.979-25.393l0.01,3.47L527.35,657.414 M573.533,630.75l-0.01-3.469l9.902-5.717l0.01,3.467L573.533,630.75 M481.297,677.991
				l-0.009-3.452l5.221-3.018l0.01,3.457L481.297,677.991 M488.995,673.546l-0.01-3.452l28.399-16.396l0.01,3.452L488.995,673.546
				 M519.599,655.878l-0.01-3.452l15.469-8.932l0.01,3.451L519.599,655.878 M536.889,645.894l-0.009-3.452l46.527-26.864l0.01,3.454
				L536.889,645.894 M511.451,649.242l-0.011-3.454l17.377-10.031l0.011,3.452L511.451,649.242 M531.032,637.938l-0.009-3.452
				l25.357-14.643l0.01,3.454L531.032,637.938 M558.607,622.017l-0.009-3.452l24.777-14.309l0.01,3.454L558.607,622.017
				 M505.88,646.464l-0.01-3.469l10.469-6.042l0.009,3.465L505.88,646.464 M518.552,639.146l-0.01-3.467l3.892-2.247l0.01,3.467
				L518.552,639.146 M524.648,635.626l-0.011-3.467l3.893-2.248l0.009,3.468L524.648,635.626 M530.745,632.108l-0.01-3.469
				l3.891-2.248l0.011,3.47L530.745,632.108 M536.841,628.587l-0.009-3.467l15.369-8.873l0.01,3.467L536.841,628.587
				 M554.415,618.44l-0.009-3.467l15.369-8.873l0.01,3.467L554.415,618.44 M571.988,608.293l-0.009-3.467l11.38-6.568l0.009,3.467
				L571.988,608.293 M501.047,643.242l-0.01-3.452l24.114-13.922l0.01,3.454L501.047,643.242 M527.366,628.048l-0.01-3.454
				l13.19-7.613l0.009,3.452L527.366,628.048 M542.76,619.16l-0.011-3.452l7.673-4.432l0.01,3.454L542.76,619.16 M552.636,613.456
				l-0.009-3.452l18.608-10.742l0.011,3.452L552.636,613.456 M573.45,601.439l-0.01-3.452l9.902-5.717l0.009,3.452L573.45,601.439
				 M481.214,648.683l-0.009-3.454l5.222-3.013l0.009,3.452L481.214,648.683 M488.912,644.239l-0.01-3.454l28.4-16.396l0.009,3.455
				L488.912,644.239 M519.516,626.569l-0.01-3.454l15.468-8.93l0.011,3.454L519.516,626.569 M537.188,616.367l-0.009-3.451
				l22.833-13.188l0.011,3.454L537.188,616.367 M562.226,601.911l-0.01-3.452l9.458-5.461l0.01,3.451L562.226,601.911
				 M573.888,595.178l-0.009-3.454l9.446-5.452l0.01,3.451L573.888,595.178 M500.999,625.921l-0.011-3.452l24.115-13.924
				l0.009,3.454L500.999,625.921 M527.217,610.784l-0.009-3.454l43.979-25.392l0.01,3.451L527.217,610.784 M573.401,584.118
				l-0.01-3.452l9.901-5.717l0.01,3.452L573.401,584.118 M481.165,631.376l-0.009-3.452l5.222-3.015l0.009,3.452L481.165,631.376
				 M488.862,626.933l-0.009-3.456l28.399-16.395l0.009,3.452L488.862,626.933 M519.466,609.263l-0.01-3.454l15.469-8.933
				l0.01,3.457L519.466,609.263 M536.757,599.278l-0.01-3.452l46.529-26.864l0.009,3.454L536.757,599.278 M510.998,602.813
				l-0.01-3.454l45.261-26.131l0.009,3.452L510.998,602.813 M558.475,575.401l-0.011-3.452l24.78-14.309l0.009,3.454
				L558.475,575.401 M506.387,599.465l-0.009-3.455l9.828-5.672l0.01,3.452L506.387,599.465 M518.419,592.518l-0.009-3.453
				l3.892-2.246l0.009,3.452L518.419,592.518 M524.516,588.996l-0.01-3.454l3.892-2.243l0.01,3.452L524.516,588.996
				 M530.612,585.477l-0.009-3.452l3.892-2.247l0.009,3.453L530.612,585.477 M536.708,581.959l-0.009-3.454l15.369-8.873l0.01,3.452
				L536.708,581.959 M554.283,571.811l-0.01-3.452l15.37-8.873l0.011,3.452L554.283,571.811 M571.857,561.665l-0.009-3.452
				l11.379-6.57l0.009,3.452L571.857,561.665 M500.915,596.627l-0.01-3.454l24.115-13.92l0.009,3.452L500.915,596.627
				 M527.233,581.434l-0.01-3.452l13.189-7.618l0.011,3.454L527.233,581.434 M542.627,572.545l-0.009-3.454l7.672-4.428l0.01,3.452
				L542.627,572.545 M552.504,566.841l-0.01-3.452l18.61-10.742l0.009,3.452L552.504,566.841 M573.318,554.827l-0.009-3.454
				l9.902-5.717l0.009,3.451L573.318,554.827 M481.082,602.067l-0.01-3.452l5.221-3.015l0.011,3.454L481.082,602.067
				 M488.779,597.624l-0.009-3.454l28.399-16.396l0.01,3.454L488.779,597.624 M519.383,579.954l-0.009-3.452l8.228-4.751l0.01,3.454
				L519.383,579.954 M528.792,574.522l-0.009-3.454l12.13-7.003l0.01,3.454L528.792,574.522 M542.105,566.837l-0.009-3.454
				l5.024-2.9l0.01,3.454L542.105,566.837 M549.101,562.798l-0.009-3.452l23.461-13.546l0.009,3.452L549.101,562.798
				 M573.757,548.563l-0.011-3.454l9.447-5.452l0.01,3.452L573.757,548.563 M500.865,579.306l-0.009-3.452l15.283-8.824l0.011,3.452
				L500.865,579.306 M518.354,569.209l-0.009-3.451l51.232-29.581l0.01,3.452L518.354,569.209 M571.791,538.358l-0.009-3.454
				l11.379-6.57l0.009,3.454L571.791,538.358 M500.849,573.32l-0.01-3.454l49.385-28.512l0.01,3.452L500.849,573.32
				 M552.439,543.535l-0.011-3.452l18.61-10.745l0.009,3.452L552.439,543.535 M573.252,531.518l-0.01-3.451l9.902-5.717l0.009,3.452
				L573.252,531.518 M481.016,578.761l-0.01-3.454l5.222-3.013l0.011,3.452L481.016,578.761 M488.713,574.317l-0.009-3.454
				l28.399-16.396l0.009,3.452L488.713,574.317 M519.317,556.647l-0.009-3.452l15.469-8.932l0.009,3.454L519.317,556.647
				 M536.99,546.443l-0.011-3.454l22.834-13.181l0.009,3.452L536.99,546.443 M562.027,531.987l-0.009-3.454l9.458-5.459l0.01,3.454
				L562.027,531.987 M573.691,525.254l-0.011-3.452l9.446-5.454l0.01,3.454L573.691,525.254 M500.799,556.012l-0.009-3.465
				l24.113-13.922l0.011,3.465L500.799,556.012 M527.02,540.875l-0.01-3.467l43.979-25.392l0.009,3.469L527.02,540.875
				 M573.203,514.209l-0.011-3.467l9.902-5.715l0.01,3.465L573.203,514.209 M480.967,561.454l-0.01-3.454l5.222-3.015l0.01,3.454
				L480.967,561.454 M488.665,557.009l-0.011-3.452l28.4-16.396l0.009,3.452L488.665,557.009 M519.269,539.339l-0.01-3.452
				l15.468-8.93l0.009,3.452L519.269,539.339 M536.559,529.356l-0.01-3.454l46.528-26.861l0.011,3.454L536.559,529.356
				 M511.12,532.705l-0.011-3.454l17.378-10.032l0.01,3.451L511.12,532.705 M530.701,521.398l-0.009-3.452l25.358-14.641
				l0.011,3.452L530.701,521.398 M558.276,505.478l-0.009-3.454l24.778-14.305l0.011,3.454L558.276,505.478 M505.549,529.91
				l-0.01-3.452l10.468-6.044l0.01,3.452L505.549,529.91 M518.222,522.595l-0.009-3.454l3.891-2.246l0.01,3.454L518.222,522.595
				 M524.317,519.074l-0.009-3.451l3.892-2.248l0.009,3.452L524.317,519.074 M530.414,515.555l-0.011-3.451l3.893-2.248l0.01,3.452
				L530.414,515.555 M536.51,512.035l-0.009-3.452l15.37-8.875l0.01,3.452L536.51,512.035 M554.084,501.891l-0.01-3.454
				l15.371-8.873l0.01,3.452L554.084,501.891 M571.659,491.741l-0.01-3.452l11.379-6.572l0.01,3.454L571.659,491.741
				 M500.716,526.705l-0.009-3.451l24.113-13.925l0.01,3.454L500.716,526.705 M527.034,511.51l-0.009-3.452l13.19-7.614l0.009,3.452
				L527.034,511.51 M542.43,502.621l-0.011-3.451l7.673-4.431l0.01,3.452L542.43,502.621 M552.306,496.92l-0.009-3.452
				l18.609-10.745l0.009,3.452L552.306,496.92 M573.119,484.903l-0.009-3.454l9.901-5.715l0.011,3.452L573.119,484.903
				 M480.884,532.146l-0.01-3.452l5.222-3.017l0.01,3.452L480.884,532.146 M488.582,527.703l-0.011-3.455l28.4-16.397l0.009,3.454
				L488.582,527.703 M519.186,510.032l-0.011-3.454l15.469-8.93l0.01,3.454L519.186,510.032 M536.858,499.829l-0.01-3.454
				l22.833-13.182l0.009,3.452L536.858,499.829 M561.896,485.374l-0.01-3.454l9.458-5.461l0.01,3.452L561.896,485.374
				 M573.558,478.639l-0.009-3.454l9.446-5.452l0.01,3.452L573.558,478.639 M589.739,455.762l-115.68,66.801
				c-2.168,1.251-3.935,4.3-3.928,6.79l0.484,166.359c0.006,2.476,1.785,3.489,3.954,2.237l115.681-66.787
				c2.167-1.251,3.935-4.313,3.927-6.805l-0.484-166.344C593.686,455.523,591.907,454.509,589.739,455.762"
            />
            <path
              fill="#EEFFFD"
              d="M532.577,686.587c-3.473,2.005-6.279,0.391-6.29-3.585c-0.011-3.991,2.775-8.818,6.249-10.821
				c3.46-2,6.278-0.407,6.289,3.581C538.836,679.74,536.038,684.589,532.577,686.587 M598.588,631.151l-132.122,76.283l0.048,16.768
				c0.003,1.32,0.464,2.265,1.205,2.734c0.792,0.5,1.905,0.46,3.132-0.25l123.464-71.295c2.39-1.379,4.329-4.738,4.321-7.47
				L598.588,631.151"
            />
            <path
              fill="#6464DD"
              d="M532.536,672.181c-3.474,2.003-6.26,6.83-6.249,10.821c0.011,3.976,2.817,5.59,6.29,3.585
				c3.46-1.998,6.259-6.847,6.248-10.825C538.814,671.773,535.997,670.181,532.536,672.181"
            />
            <polyline
              fill="#42E8E0"
              points="516.971,507.851 488.571,524.248 488.582,527.703 516.98,511.305 516.971,507.851 			"
            />
            <polyline
              fill="#FF2C9C"
              points="534.644,497.648 519.175,506.578 519.186,510.032 534.654,501.103 534.644,497.648 			"
            />
            <polyline
              fill="#FF2C9C"
              points="486.096,525.677 480.874,528.693 480.884,532.146 486.105,529.129 486.096,525.677 			"
            />
            <polyline
              fill="#42E8E0"
              points="559.682,483.193 536.848,496.375 536.858,499.829 559.691,486.646 559.682,483.193 			"
            />
            <polyline
              fill="#42E8E0"
              points="551.87,499.708 536.5,508.583 536.51,512.035 551.88,503.16 551.87,499.708 			"
            />
            <polyline
              fill="#FFFFFF"
              points="569.445,489.563 554.074,498.437 554.084,501.891 569.455,493.016 569.445,489.563 			"
            />
            <polyline
              fill="#FF2C9C"
              points="528.488,519.219 511.109,529.251 511.12,532.705 528.498,522.67 528.488,519.219 			"
            />
            <polyline
              fill="#FFFFFF"
              points="524.82,509.329 500.707,523.254 500.716,526.705 524.83,512.783 524.82,509.329 			"
            />
            <polyline
              fill="#42E8E0"
              points="540.215,500.443 527.025,508.058 527.034,511.51 540.225,503.896 540.215,500.443 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.011,475.734 573.11,481.449 573.119,484.903 583.022,479.187 583.011,475.734 			"
            />
            <polyline
              fill="#42E8E0"
              points="570.906,482.723 552.296,493.468 552.306,496.92 570.915,486.175 570.906,482.723 			"
            />
            <polyline
              fill="#FF2C9C"
              points="550.092,494.739 542.419,499.17 542.43,502.621 550.102,498.191 550.092,494.739 			"
            />
            <polyline
              fill="#42E8E0"
              points="516.007,520.414 505.539,526.458 505.549,529.91 516.017,523.866 516.007,520.414 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.028,481.717 571.649,488.289 571.659,491.741 583.039,485.171 583.028,481.717 			"
            />
            <polyline
              fill="#42E8E0"
              points="522.104,516.895 518.213,519.141 518.222,522.595 522.114,520.349 522.104,516.895 			"
            />
            <polyline
              fill="#42E8E0"
              points="528.2,513.375 524.308,515.623 524.317,519.074 528.209,516.827 528.2,513.375 			"
            />
            <polyline
              fill="#42E8E0"
              points="534.296,509.855 530.403,512.104 530.414,515.555 534.306,513.308 534.296,509.855 			"
            />
            <polyline
              fill="#42E8E0"
              points="556.05,503.306 530.692,517.946 530.701,521.398 556.061,506.758 556.05,503.306 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.045,487.719 558.267,502.023 558.276,505.478 583.056,491.173 583.045,487.719 			"
            />
            <polyline
              fill="#42E8E0"
              points="571.344,476.459 561.885,481.92 561.896,485.374 571.354,479.911 571.344,476.459 			"
            />
            <polyline
              fill="#42E8E0"
              points="582.995,469.732 573.549,475.185 573.558,478.639 583.005,473.185 582.995,469.732 			"
            />
            <polyline
              fill="#FF2C9C"
              points="517.302,624.389 488.902,640.785 488.912,644.239 517.311,627.844 517.302,624.389 			"
            />
            <polyline
              fill="#42E8E0"
              points="534.974,614.186 519.505,623.115 519.516,626.569 534.984,617.64 534.974,614.186 			"
            />
            <polyline
              fill="#FF2C9C"
              points="486.427,642.216 481.205,645.229 481.214,648.683 486.436,645.668 486.427,642.216 			"
            />
            <polyline
              fill="#FF2C9C"
              points="560.011,599.729 537.178,612.916 537.188,616.367 560.022,603.183 560.011,599.729 			"
            />
            <polyline
              fill="#FF2C9C"
              points="552.2,616.247 536.832,625.12 536.841,628.587 552.21,619.714 552.2,616.247 			"
            />
            <polyline
              fill="#42E8E0"
              points="569.774,606.101 554.406,614.974 554.415,618.44 569.785,609.567 569.774,606.101 			"
            />
            <polyline
              fill="#FF2C9C"
              points="528.817,635.757 511.44,645.788 511.451,649.242 528.828,639.209 528.817,635.757 			"
            />
            <polyline
              fill="#42E8E0"
              points="525.151,625.868 501.037,639.79 501.047,643.242 525.161,629.322 525.151,625.868 			"
            />
            <polyline
              fill="#FF2C9C"
              points="540.546,616.98 527.356,624.594 527.366,628.048 540.555,620.433 540.546,616.98 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.342,592.271 573.44,597.987 573.45,601.439 583.352,595.723 583.342,592.271 			"
            />
            <polyline
              fill="#FFFFFF"
              points="571.235,599.262 552.627,610.004 552.636,613.456 571.246,602.714 571.235,599.262 			"
            />
            <polyline
              fill="#42E8E0"
              points="550.422,611.276 542.749,615.708 542.76,619.16 550.432,614.73 550.422,611.276 			"
            />
            <polyline
              fill="#42E8E0"
              points="516.338,636.953 505.87,642.995 505.88,646.464 516.348,640.418 516.338,636.953 			"
            />
            <polyline
              fill="#FF2C9C"
              points="583.359,598.258 571.979,604.826 571.988,608.293 583.368,601.725 583.359,598.258 			"
            />
            <polyline
              fill="#FF2C9C"
              points="522.434,633.432 518.542,635.679 518.552,639.146 522.443,636.898 522.434,633.432 			"
            />
            <polyline
              fill="#42E8E0"
              points="528.531,629.911 524.638,632.159 524.648,635.626 528.54,633.379 528.531,629.911 			"
            />
            <polyline
              fill="#42E8E0"
              points="534.626,626.392 530.735,628.64 530.745,632.108 534.637,629.861 534.626,626.392 			"
            />
            <polyline
              fill="#42E8E0"
              points="556.38,619.843 531.023,634.485 531.032,637.938 556.39,623.297 556.38,619.843 			"
            />
            <polyline
              fill="#FFFFFF"
              points="583.375,604.256 558.598,618.564 558.607,622.017 583.386,607.71 583.375,604.256 			"
            />
            <polyline
              fill="#42E8E0"
              points="571.674,592.998 562.216,598.459 562.226,601.911 571.684,596.449 571.674,592.998 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.325,586.271 573.879,591.724 573.888,595.178 583.334,589.723 583.325,586.271 			"
            />
            <polyline
              fill="#42E8E0"
              points="517.169,577.774 488.77,594.17 488.779,597.624 517.179,581.229 517.169,577.774 			"
            />
            <polyline
              fill="#FFFFFF"
              points="527.601,571.751 519.374,576.502 519.383,579.954 527.611,575.205 527.601,571.751 			"
            />
            <polyline
              fill="#FF2C9C"
              points="486.293,595.601 481.072,598.615 481.082,602.067 486.304,599.055 486.293,595.601 			"
            />
            <polyline
              fill="#42E8E0"
              points="540.913,564.065 528.783,571.068 528.792,574.522 540.923,567.52 540.913,564.065 			"
            />
            <polyline
              fill="#42E8E0"
              points="572.552,545.8 549.091,559.346 549.101,562.798 572.561,549.252 572.552,545.8 			"
            />
            <polyline
              fill="#FFFFFF"
              points="552.068,569.632 536.699,578.505 536.708,581.959 552.078,573.084 552.068,569.632 			"
            />
            <polyline
              fill="#FF2C9C"
              points="569.643,559.485 554.273,568.358 554.283,571.811 569.653,562.938 569.643,559.485 			"
            />
            <polyline
              fill="#42E8E0"
              points="525.02,579.253 500.905,593.173 500.915,596.627 525.028,582.705 525.02,579.253 			"
            />
            <polyline
              fill="#42E8E0"
              points="540.413,570.363 527.224,577.981 527.233,581.434 540.423,573.817 540.413,570.363 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.21,545.656 573.309,551.373 573.318,554.827 583.22,549.107 583.21,545.656 			"
            />
            <polyline
              fill="#42E8E0"
              points="571.104,552.646 552.494,563.389 552.504,566.841 571.113,556.099 571.104,552.646 			"
            />
            <polyline
              fill="#FF2C9C"
              points="550.29,564.663 542.618,569.091 542.627,572.545 550.3,568.115 550.29,564.663 			"
            />
            <polyline
              fill="#42E8E0"
              points="516.206,590.338 506.378,596.01 506.387,599.465 516.216,593.79 516.206,590.338 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.227,551.643 571.848,558.213 571.857,561.665 583.236,555.095 583.227,551.643 			"
            />
            <polyline
              fill="#FF2C9C"
              points="522.302,586.818 518.41,589.064 518.419,592.518 522.312,590.271 522.302,586.818 			"
            />
            <polyline
              fill="#42E8E0"
              points="528.397,583.299 524.506,585.542 524.516,588.996 528.408,586.751 528.397,583.299 			"
            />
            <polyline
              fill="#FF2C9C"
              points="534.495,579.777 530.603,582.024 530.612,585.477 534.504,583.23 534.495,579.777 			"
            />
            <polyline
              fill="#42E8E0"
              points="556.249,573.228 510.988,599.358 510.998,602.813 556.258,576.68 556.249,573.228 			"
            />
            <polyline
              fill="#FFFFFF"
              points="583.244,557.641 558.464,571.949 558.475,575.401 583.253,561.095 583.244,557.641 			"
            />
            <polyline
              fill="#42E8E0"
              points="547.121,560.482 542.097,563.383 542.105,566.837 547.13,563.937 547.121,560.482 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.193,539.656 573.746,545.108 573.757,548.563 583.203,543.108 583.193,539.656 			"
            />
            <polyline
              fill="#42E8E0"
              points="517.103,554.468 488.704,570.863 488.713,574.317 517.112,557.92 517.103,554.468 			"
            />
            <polyline
              fill="#42E8E0"
              points="534.776,544.264 519.308,553.195 519.317,556.647 534.786,547.718 534.776,544.264 			"
            />
            <polyline
              fill="#FF2C9C"
              points="486.228,572.294 481.006,575.307 481.016,578.761 486.238,575.746 486.228,572.294 			"
            />
            <polyline
              fill="#42E8E0"
              points="559.813,529.809 536.979,542.989 536.99,546.443 559.823,533.261 559.813,529.809 			"
            />
            <polyline
              fill="#FF2C9C"
              points="550.224,541.354 500.839,569.866 500.849,573.32 550.234,544.807 550.224,541.354 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.144,522.35 573.242,528.066 573.252,531.518 583.153,525.802 583.144,522.35 			"
            />
            <polyline
              fill="#FFFFFF"
              points="571.038,529.338 552.428,540.083 552.439,543.535 571.047,532.79 571.038,529.338 			"
            />
            <polyline
              fill="#42E8E0"
              points="516.14,567.029 500.856,575.854 500.865,579.306 516.15,570.481 516.14,567.029 			"
            />
            <polyline
              fill="#FFFFFF"
              points="583.162,528.334 571.782,534.904 571.791,538.358 583.171,531.788 583.162,528.334 			"
            />
            <polyline
              fill="#42E8E0"
              points="569.576,536.177 518.344,565.758 518.354,569.209 569.586,539.629 569.576,536.177 			"
            />
            <polyline
              fill="#42E8E0"
              points="571.476,523.074 562.019,528.533 562.027,531.987 571.486,526.528 571.476,523.074 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.126,516.348 573.68,521.802 573.691,525.254 583.137,519.802 583.126,516.348 			"
            />
            <polyline
              fill="#42E8E0"
              points="517.054,537.161 488.654,553.557 488.665,557.009 517.063,540.613 517.054,537.161 			"
            />
            <polyline
              fill="#42E8E0"
              points="534.727,526.957 519.259,535.887 519.269,539.339 534.736,530.409 534.727,526.957 			"
            />
            <polyline
              fill="#FF2C9C"
              points="486.179,554.985 480.957,558 480.967,561.454 486.189,558.439 486.179,554.985 			"
            />
            <polyline
              fill="#FFFFFF"
              points="524.903,538.625 500.791,552.547 500.799,556.012 524.914,542.09 524.903,538.625 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.094,505.027 573.192,510.742 573.203,514.209 583.104,508.492 583.094,505.027 			"
            />
            <polyline
              fill="#42E8E0"
              points="570.989,512.017 527.009,537.408 527.02,540.875 570.998,515.485 570.989,512.017 			"
            />
            <polyline
              fill="#FF2C9C"
              points="583.077,499.041 536.549,525.902 536.559,529.356 583.088,502.495 583.077,499.041 			"
            />
            <polyline
              fill="#42E8E0"
              points="517.384,653.697 488.985,670.094 488.995,673.546 517.394,657.149 517.384,653.697 			"
            />
            <polyline
              fill="#FF2C9C"
              points="535.058,643.494 519.589,652.426 519.599,655.878 535.067,646.945 535.058,643.494 			"
            />
            <polyline
              fill="#FF2C9C"
              points="486.509,671.521 481.288,674.539 481.297,677.991 486.519,674.979 486.509,671.521 			"
            />
            <polyline
              fill="#FFFFFF"
              points="525.234,655.162 501.121,669.082 501.13,672.551 525.244,658.629 525.234,655.162 			"
            />
            <polyline
              fill="#FF2C9C"
              points="583.425,621.564 573.523,627.281 573.533,630.75 583.435,625.031 583.425,621.564 			"
            />
            <polyline
              fill="#42E8E0"
              points="571.319,628.553 527.34,653.945 527.35,657.414 571.329,632.022 571.319,628.553 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.408,615.577 536.88,642.441 536.889,645.894 583.418,619.031 583.408,615.577 			"
            />
            <polyline
              fill="#42E8E0"
              points="517.252,607.082 488.853,623.477 488.862,626.933 517.261,610.534 517.252,607.082 			"
            />
            <polyline
              fill="#FF2C9C"
              points="534.925,596.876 519.457,605.809 519.466,609.263 534.935,600.333 534.925,596.876 			"
            />
            <polyline
              fill="#FF2C9C"
              points="486.378,624.909 481.156,627.924 481.165,631.376 486.387,628.361 486.378,624.909 			"
            />
            <polyline
              fill="#FFFFFF"
              points="525.103,608.545 500.988,622.469 500.999,625.921 525.111,611.999 525.103,608.545 			"
            />
            <polyline
              fill="#FF2C9C"
              points="583.292,574.949 573.391,580.666 573.401,584.118 583.302,578.401 583.292,574.949 			"
            />
            <polyline
              fill="#42E8E0"
              points="571.187,581.938 527.208,607.33 527.217,610.784 571.197,585.39 571.187,581.938 			"
            />
            <polyline
              fill="#42E8E0"
              points="583.276,568.962 536.747,595.826 536.757,599.278 583.286,572.416 583.276,568.962 			"
            />
            <path
              fill="#6464DD"
              d="M461.088,550.479c-0.321,0.185-0.578,0.844-0.576,1.452l0.173,61.162
				c0.001,0.608,0.262,0.968,0.582,0.784s0.589-0.852,0.588-1.46l-0.174-61.163C461.68,550.646,461.408,550.293,461.088,550.479"
            />
            <path
              fill="#6464DD"
              d="M461.273,615.844c-0.319,0.184-0.578,0.843-0.576,1.466l0.051,17.915
				c0.002,0.623,0.262,0.982,0.582,0.799s0.59-0.852,0.588-1.473l-0.051-17.917C461.866,616.013,461.593,615.658,461.273,615.844"
            />
          </g>
        </g>
      </g>
      <g id="cloud">
        <g enable-background="new    ">
          <defs>
            <polygon
              id="SVGID_15_"
              enable-background="new    "
              points="466.917,445.135 466.782,397.713 534.308,358.727 534.442,406.147 
							"
            />
          </defs>
          <clipPath id="SVGID_16_">
            <use xlink:href="#SVGID_15_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_16_)">
            <path
              fill="#DEEFFF"
              d="M493.558,383.091c-8.695,5.018-15.731,15.9-15.708,24.291l0.014,0.389
				c-6.314,5.136-11.016,13.377-10.997,19.915c0.013,4.404,2.133,7.695,5.477,8.345c-1.369-1.511-2.158-3.774-2.166-6.457
				c-0.019-6.538,4.694-14.787,10.997-19.915l-0.002-0.396c-0.023-8.392,7.002-19.267,15.697-24.287
				c2.013-1.161,3.938-1.908,5.709-2.263c0.27-0.652,0.579-1.302,0.925-1.949C500.788,380.197,497.326,380.914,493.558,383.091"
            />
            <path
              fill="#FFFFFF"
              d="M496.87,384.976c-8.695,5.021-15.72,15.896-15.697,24.287l0.002,0.396
				c-6.302,5.128-11.015,13.377-10.997,19.915c0.007,2.683,0.796,4.946,2.166,6.457c2.061,2.272,5.435,2.834,9.438,0.522
				l29.335-16.937c7.143-4.126,12.631-13.87,12.61-20.959c0-0.023-0.001-0.049-0.001-0.071l-18.657,10.771
				c-3.069,1.772-5.609,1.133-6.987-0.857c-2.683-0.148-4.421-2.569-4.431-5.901c-0.013-4.571,3.265-10.334,7.683-13.922
				l-0.013-0.274c-0.005-1.789,0.451-3.741,1.258-5.69C500.808,383.068,498.883,383.814,496.87,384.976"
            />
            <path
              fill="#60BFFF"
              d="M526.383,375.482c0.165,0.634,0.254,1.339,0.256,2.115l0.001,0.27c1.255-0.428,2.421-0.575,3.448-0.461
				C529.268,376.215,527.976,375.552,526.383,375.482 M512.3,371.432c-3.604,2.082-6.797,5.595-8.797,9.332
				c-0.346,0.647-0.655,1.296-0.925,1.949c-0.807,1.949-1.263,3.901-1.258,5.69l0.013,0.274c-4.418,3.588-7.695,9.351-7.683,13.922
				c0.01,3.332,1.748,5.753,4.431,5.901c-0.712-1.027-1.115-2.415-1.119-4.017c-0.014-4.571,3.276-10.324,7.681-13.918v-0.268
				c-0.017-5.874,4.896-13.472,10.967-16.978c2.451-1.415,4.716-1.946,6.548-1.693C520.345,369.185,516.607,368.946,512.3,371.432"
            />
            <path
              fill="#A6DFFF"
              d="M515.611,373.32c-6.071,3.506-10.984,11.104-10.967,16.978v0.268c-4.405,3.594-7.695,9.347-7.681,13.918
				c0.004,1.602,0.407,2.989,1.119,4.017c1.378,1.99,3.918,2.63,6.987,0.857l18.657-10.771l1.848-1.065
				c4.988-2.881,8.817-9.685,8.803-14.637c-0.009-3.27-1.708-5.196-4.288-5.478c-1.027-0.113-2.193,0.034-3.448,0.461l-0.001-0.27
				c-0.002-0.776-0.092-1.481-0.256-2.115c-0.581-2.229-2.114-3.564-4.224-3.856C520.327,371.374,518.062,371.905,515.611,373.32"
            />
          </g>
        </g>
      </g>
      <g id="tag-02">
        <g enable-background="new    ">
          <defs>
            <polygon
              id="SVGID_17_"
              enable-background="new    "
              points="689.599,354.89 689.765,295.92 755.74,334.01 755.572,392.98 			"
            />
          </defs>
          <clipPath id="SVGID_18_">
            <use xlink:href="#SVGID_17_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_18_)">
            <path
              fill="#42E8E0"
              d="M753.732,383.592l-1.211,0.67C752.965,384.112,753.371,383.886,753.732,383.592 M747.551,329.282
				l-45.359-26.187c-2.478-1.431-4.697-1.449-6.203-0.319l0.754-0.433l1.145,3.157c0.015,0.007,0.028,0.015,0.042,0.022
				l45.371,26.195c4.506,2.603,8.164,8.924,8.148,14.117l-0.09,32.238l2.832,5.084c0.892-0.99,1.418-2.503,1.422-4.457l0.102-35.294
				C755.728,338.214,752.07,331.892,747.551,329.282"
            />
            <path
              fill="#03F4D1"
              d="M696.742,302.344l-0.754,0.433l-3.305,1.902c1.412-0.539,3.226-0.309,5.203,0.822L696.742,302.344"
            />
            <path
              fill="#42E8E0"
              d="M751.358,378.074l-0.007,3.058c-0.009,2.123-0.63,3.73-1.674,4.706l2.844-1.576l1.211-0.67l0.535-0.294
				l-0.077-0.14L751.358,378.074"
            />
            <path
              fill="#CAFFF9"
              d="M693.972,312.651c0.009-2.49,1.776-3.511,3.942-2.259l45.371,26.194c2.168,1.252,3.923,4.306,3.916,6.797
				l-0.1,35.293c-0.008,2.492-1.773,3.499-3.941,2.248l-45.37-26.196c-2.152-1.242-3.898-4.249-3.915-6.729
				c-0.002-0.013-0.002-0.03-0.002-0.053L693.972,312.651 M743.3,331.719l-45.371-26.195c-0.014-0.007-0.027-0.015-0.042-0.022
				c-1.978-1.131-3.791-1.361-5.203-0.822c-1.803,0.687-2.951,2.619-2.96,5.521l-0.099,35.293
				c-0.016,5.18,3.631,11.508,8.149,14.119l45.371,26.195c2.66,1.534,5.03,1.432,6.532,0.031c1.044-0.976,1.665-2.583,1.674-4.706
				l0.007-3.058l0.09-32.238C751.464,340.643,747.806,334.321,743.3,331.719"
            />
            <path
              fill="#42E8E0"
              d="M712.385,336.379l-1.355-0.782l-0.024,8.986l1.097,0.633c2.449,1.414,3.686,0.612,3.694-2.417
				C715.805,339.842,714.663,337.695,712.385,336.379 M720.703,352.592l0.029-10.542l2.514,1.45l-0.031,10.542L720.703,352.592
				 M729.293,355.171l0.049,0.028c0.026-0.581,0.152-1.231,0.376-1.938l1.779-4.997l2.621,1.515l-3.525,8.522l-2.573-1.486
				l-3.469-12.561l2.623,1.514l1.757,7.038C729.125,353.671,729.247,354.464,729.293,355.171 M706.226,342.32l-8.13-8.833
				l0.005-1.344l8.154,0.017l-0.009,2.264l-5.61-0.25l5.596,5.896L706.226,342.32 M734.809,358.825l0.007-2.251l5.626,0.583
				l-5.611-6.229l0.009-2.264l8.139,9.391l-0.003,1.343L734.809,358.825 M708.461,345.524l0.04-13.783l3.767,2.175
				c1.959,1.13,3.473,2.6,4.553,4.415c1.079,1.814,1.618,3.783,1.61,5.919c-0.005,2.265-0.564,3.685-1.7,4.234
				c-1.125,0.57-2.752,0.226-4.87-0.998L708.461,345.524 M721.992,341.458c-0.898-0.519-1.354-1.249-1.35-2.197
				c0.003-0.933,0.46-1.138,1.357-0.619c0.911,0.526,1.367,1.256,1.363,2.19c0,0.453-0.113,0.742-0.347,0.848
				C722.795,341.808,722.447,341.722,721.992,341.458 M743.285,336.586c2.032,1.173-43.114-21.235-43.114-21.235
				c-2.167-1.251-3.934-0.245-3.941,2.26c0,0-2.237,30.474-2.351,30.408c-0.004-0.001-0.004-0.007-0.004-0.021
				c0.017,2.48,1.763,5.487,3.915,6.729l45.37,26.196c2.168,1.251,3.934,0.245,3.941-2.248l0.1-35.293
				C747.208,340.893,745.453,337.838,743.285,336.586"
            />
            <polyline
              fill="#FFFFFF"
              points="706.255,332.16 698.101,332.144 698.096,333.488 706.226,342.32 706.231,340.07 
				700.636,334.174 706.246,334.424 706.255,332.16 			"
            />
            <path
              fill="#FFFFFF"
              d="M711.005,344.584l0.024-8.986l1.355,0.782c2.278,1.316,3.42,3.462,3.411,6.42
				c-0.009,3.029-1.245,3.831-3.694,2.417L711.005,344.584 M712.268,333.917l-3.767-2.175l-0.04,13.783l3.399,1.963
				c2.118,1.223,3.745,1.567,4.87,0.998c1.136-0.549,1.695-1.97,1.7-4.234c0.008-2.136-0.531-4.105-1.61-5.919
				C715.74,336.516,714.227,335.047,712.268,333.917"
            />
            <path
              fill="#FFFFFF"
              d="M723.246,343.5l-2.514-1.45l-0.029,10.542l2.512,1.45L723.246,343.5 M722,338.643
				c-0.897-0.519-1.354-0.314-1.357,0.619c-0.004,0.948,0.451,1.678,1.35,2.197c0.455,0.264,0.803,0.35,1.024,0.223
				c0.233-0.106,0.347-0.395,0.347-0.848C723.367,339.899,722.911,339.168,722,338.643"
            />
            <path
              fill="#FFFFFF"
              d="M734.118,349.779l-2.621-1.515l-1.779,4.997c-0.224,0.707-0.35,1.357-0.376,1.938l-0.049-0.028
				c-0.046-0.708-0.168-1.5-0.362-2.366l-1.757-7.038l-2.623-1.514l3.469,12.561l2.573,1.486L734.118,349.779"
            />
            <polyline
              fill="#FFFFFF"
              points="734.839,348.663 734.83,350.927 740.441,357.156 734.815,356.574 734.809,358.825 
				742.975,359.397 742.978,358.054 734.839,348.663 			"
            />
            <path
              fill="#1FC1B5"
              d="M743.285,336.586l-45.371-26.194c-2.166-1.252-3.934-0.231-3.942,2.259l-0.099,35.295
				c0,0.023,0,0.04,0.002,0.053c0,0.013,0,0.02,0.004,0.021c0.113,0.066,2.351-30.408,2.351-30.408
				c0.008-2.504,1.774-3.511,3.941-2.26C700.171,315.352,745.317,337.76,743.285,336.586"
            />
          </g>
        </g>
      </g>
      <g id="tag-03">
        <g enable-background="new    ">
          <defs>
            <polygon
              id="SVGID_19_"
              enable-background="new    "
              points="737.196,475.967 737.364,417.012 803.327,455.094 803.16,514.049 
							"
            />
          </defs>
          <clipPath id="SVGID_20_">
            <use xlink:href="#SVGID_19_" overflow="visible" />
          </clipPath>
          <g clip-path="url(#SVGID_20_)">
            <path
              fill="#FF2C9C"
              d="M801.273,504.715l-1.128,0.621C800.555,505.19,800.933,504.981,801.273,504.715 M795.15,450.374
				l-45.358-26.188c-2.479-1.433-4.698-1.45-6.204-0.321l0.751-0.432l1.135,3.138c0.02,0.009,0.038,0.019,0.055,0.029l45.358,26.188
				c4.52,2.607,8.179,8.944,8.164,14.138l-0.093,32.23l2.824,5.078c0.889-0.99,1.412-2.505,1.419-4.451l0.099-35.293
				C803.315,459.296,799.669,452.982,795.15,450.374"
            />
            <path
              fill="#FF2C9C"
              d="M744.339,423.434l-0.751,0.432l-3.263,1.878c1.404-0.518,3.196-0.285,5.148,0.828L744.339,423.434"
            />
            <path
              fill="#FF2C9C"
              d="M798.958,499.157l-0.009,3.048c-0.006,2.128-0.626,3.733-1.67,4.707l2.866-1.576l1.128-0.621l0.593-0.327
				l-0.084-0.152L798.958,499.157"
            />
            <path
              fill="#FFAEE0"
              d="M741.571,433.741c0.009-2.503,1.776-3.511,3.944-2.258l45.355,26.188
				c2.183,1.259,3.937,4.299,3.931,6.805l-0.101,35.277c-0.008,2.505-1.774,3.511-3.954,2.254l-45.357-26.188
				c-2.153-1.242-3.898-4.248-3.917-6.741c0-0.013,0-0.029,0-0.055L741.571,433.741 M790.887,452.789l-45.358-26.188
				c-0.017-0.011-0.035-0.021-0.055-0.029c-1.952-1.113-3.744-1.346-5.148-0.828c-1.825,0.677-2.992,2.62-3,5.547l-0.102,35.279
				c-0.016,5.193,3.632,11.508,8.153,14.119l45.355,26.188c2.672,1.54,5.046,1.445,6.547,0.036c1.044-0.974,1.664-2.579,1.67-4.707
				l0.009-3.048l0.093-32.23C799.065,461.733,795.406,455.396,790.887,452.789"
            />
            <path
              fill="#FF2C9C"
              d="M763.924,469.24l-8.142-8.838l0.003-1.358l8.168,0.036l-0.008,2.265l-5.625-0.258l5.61,5.891
				L763.924,469.24 M772.31,474.084l0.007-2.265l5.623,0.596l-5.607-6.228l0.008-2.265l8.139,9.378l-0.004,1.358L772.31,474.084
				 M764.756,471.636l4.51-11.216l2.279,1.313l-4.51,11.219L764.756,471.636 M790.871,457.671
				c2.046,1.179-43.101-21.229-43.101-21.229c-2.18-1.258-3.946-0.25-3.954,2.239c0,0-2.227,30.483-2.34,30.418
				c0-0.002-0.002-0.009-0.004-0.021c0.019,2.493,1.764,5.499,3.917,6.741l45.357,26.188c2.18,1.257,3.946,0.251,3.954-2.254
				l0.101-35.277C794.808,461.97,793.054,458.93,790.871,457.671"
            />
            <polyline
              fill="#FFFFFF"
              points="763.953,459.08 755.785,459.044 755.782,460.402 763.924,469.24 763.931,466.978 
				758.32,461.087 763.945,461.345 763.953,459.08 			"
            />
            <polyline
              fill="#FFFFFF"
              points="771.545,461.733 769.266,460.42 764.756,471.636 767.035,472.952 771.545,461.733 			"
            />
            <polyline
              fill="#FFFFFF"
              points="772.34,463.923 772.332,466.188 777.939,472.415 772.316,471.819 772.31,474.084 
				780.475,474.659 780.479,473.301 772.34,463.923 			"
            />
            <path
              fill="#D81A87"
              d="M790.871,457.671l-45.355-26.188c-2.168-1.253-3.936-0.245-3.944,2.258l-0.099,35.281
				c0,0.025,0,0.042,0,0.055c0.002,0.013,0.004,0.02,0.004,0.021c0.113,0.065,2.34-30.418,2.34-30.418
				c0.008-2.489,1.774-3.497,3.954-2.239C747.771,436.441,792.917,458.85,790.871,457.671"
            />
          </g>
        </g>
      </g>
      <g id="Layer_2">
        <polyline
          fill="#42E8E0"
          points="785.644,145.119 751.603,125.464 751.491,164.58 785.532,184.233 785.644,145.119 	"
        />
        <polyline
          fill="#FF2C9C"
          points="805.867,208.603 773.156,189.717 773.049,227.304 805.76,246.189 805.867,208.603 	"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
