<template>
  <div id="what-i-do" class="mt-10">
    <description class="mb-8"></description>
    <tech></tech>
    <div class="next-section d-flex justify-center align-center">
      <router-link
        :to="{ name: 'projects' }"
        class="text-body-1 text-md-h2 darkText--text"
      >
        Projects
        <v-icon color="" class="ms-2">
          {{ assets.mdiArrowRight }}
        </v-icon>
      </router-link>
    </div>
    <v-icon color="#eaeaea" large id="arrow-down">
      {{ assets.mdiArrowDown }}
    </v-icon>
  </div>
</template>

<script>
import description from './description/index'
import tech from './tech/tech'
import { mdiArrowRight, mdiArrowDown } from '@mdi/js'

export default {
  name: 'what-i-do',

  components: { description, tech },

  data() {
    return {
      assets: { mdiArrowRight, mdiArrowDown },
    }
  },

  methods: {
    setup() {
      const rule = this.CSSRulePlugin.getRule('.next-section a:after')
      const element = document.querySelector('.next-section a')

      element.addEventListener('mouseenter', () => {
        this.gsap.to(rule, {
          width: '100%',
          duration: 0.5,
          marginLeft: '50%',
          ease: 'power2.out',
        })
      })
      element.addEventListener('mouseleave', () => {
        this.gsap.to(rule, {
          width: '0%',
          duration: 0.5,
          marginLeft: '50%',
          ease: 'power2.out',
        })
      })

      let tl = this.gsap.timeline({
        delay: 2,
        repeat: -1,
        yoyo: true,
        repeatDelay: 3,
      })

      tl.to('#arrow-down', {
        y: 20,
        duration: 0.5,
      })
      tl.to('#arrow-down', {
        y: -20,
        duration: 0.5,
      })
    },
  },

  mounted() {
    this.setup()
  },

  metaInfo() {
    return {
      title: 'What I Do',
      titleTemplate: '%s - Wael Dev',
      meta: [
        {
          vmid: 'description',
          property: 'description',
          content:
            'A brief introduction to me as a web developer and an overview of my professional vision and career',
        },
        {
          vmid: 'language',
          property: 'language',
          content: 'en-EN',
        },
      ],
    }
  },
}
</script>

<style>
#what-i-do {
  position: relative;
}

#arrow-down {
  position: absolute;
  left: 10px;
  top: 550px;
}
</style>
