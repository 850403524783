<template>
  <div id="web-apps" class="d-flex flex-column align-center">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="241.21 141.82 623 588"
      enable-background="new 241.21 141.82 623 588"
      xml:space="preserve"
    >
      <path
        fill="#6C63FF"
        d="M284.11,403.769c-6.74,6.762-38.5,14.141-38.5,14.141s7.31-31.78,14-38.53
	c6.735-6.754,17.671-6.77,24.425-0.035c6.754,6.735,6.77,17.671,0.035,24.424H284.11z"
      />
      <path
        fill="#6C63FF"
        d="M290,473.599c-5.5,7.791-35.56,20.441-35.56,20.441s1.83-32.561,7.33-40.35
	c5.498-7.797,16.274-9.658,24.07-4.16C293.636,455.029,295.498,465.804,290,473.599z"
      />
      <path
        opacity="0.25"
        enable-background="new    "
        d="M284.11,403.769c-6.74,6.762-38.5,14.141-38.5,14.141s7.31-31.78,14-38.53
	c6.735-6.754,17.671-6.77,24.425-0.035c6.754,6.735,6.77,17.671,0.035,24.424H284.11z"
      />
      <path
        opacity="0.25"
        enable-background="new    "
        d="M290,473.599c-5.5,7.791-35.56,20.441-35.56,20.441s1.83-32.561,7.33-40.35
	c5.498-7.797,16.274-9.658,24.07-4.16C293.636,455.029,295.498,465.804,290,473.599z"
      />
      <path
        fill="#C8CAD7"
        d="M648,715.41v6.41H458.38v-5.221c32.279-11.959,48.751-47.822,36.791-80.102
	c-5.753-15.527-17.446-28.125-32.501-35.018h184.08c-31.307,14.367-45.04,51.393-30.674,82.699
	C622.498,698.173,633.869,709.296,648,715.41z"
      />
      <path
        opacity="0.1"
        enable-background="new    "
        d="M646.75,601.49c-17.14,7.865-29.805,23.057-34.46,41.33H497.13
	c-4.655-18.273-17.32-33.465-34.46-41.33H646.75z"
      />
      <path
        fill="#C8CAD7"
        d="M241.21,568.82v36c0,19.881,14.55,36,32.5,36h558c17.95,0,32.5-16.119,32.5-36v-36H241.21z"
      />
      <path
        opacity="0.1"
        enable-background="new    "
        d="M648,715.41v6.41H458.38v-5.221c5.198-1.93,10.115-4.545,14.62-7.779h163.4
	C640.021,711.425,643.909,713.634,648,715.41z"
      />
      <path
        fill="#C8CAD7"
        d="M405.21,710.82h296c5.247,0,9.5,4.254,9.5,9.5l0,0c0,5.246-4.253,9.5-9.5,9.5h-296
	c-5.247,0-9.5-4.254-9.5-9.5l0,0C395.71,715.074,399.963,710.82,405.21,710.82z"
      />
      <path
        fill="#474157"
        d="M864.21,174.32c0-17.949-14.551-32.5-32.5-32.5l0,0h-558c-17.949,0-32.5,14.551-32.5,32.5l0,0v401.5h623
	V174.32z"
      />
      <path
        fill="#EDEDF4"
        d="M274.21,172.82h557c8.837,0,16,7.164,16,16v345c0,8.836-7.163,16-16,16h-557c-8.836,0-16-7.164-16-16v-345
	C258.21,179.983,265.374,172.82,274.21,172.82z"
      />
      <circle fill="#FFFFFF" cx="552.71" cy="157.32" r="7.5" />
      <path
        fill="#C8CAD7"
        d="M847.21,188.82h-589c0-8.836,7.164-16,16-16h557C840.047,172.82,847.21,179.983,847.21,188.82z"
      />
      <circle fill="#EDEDF4" cx="274.21" cy="180.82" r="4" />
      <circle fill="#EDEDF4" cx="286.21" cy="180.82" r="4" />
      <circle fill="#EDEDF4" cx="298.21" cy="180.82" r="4" />
      <rect x="280.21" y="202.32" fill="#6C63FF" width="151" height="9" />
      <rect x="280.21" y="465.32" fill="#6C63FF" width="151" height="9" />
      <g id="bars-1">
        <rect
          x="280.21"
          y="485.32"
          opacity="0.2"
          fill="#6C63FF"
          enable-background="new    "
          width="280"
          height="9"
        />
        <rect
          x="280.21"
          y="498.33"
          opacity="0.2"
          fill="#6C63FF"
          enable-background="new    "
          width="280"
          height="9"
        />
        <rect
          x="280.21"
          y="511.33"
          opacity="0.2"
          fill="#6C63FF"
          enable-background="new    "
          width="280"
          height="9"
        />
      </g>
      <rect
        x="280.21"
        y="226.32"
        opacity="0.2"
        fill="#6C63FF"
        enable-background="new    "
        width="278"
        height="151"
      />
      <g id="headlines">
        <rect
          x="283.21"
          y="391.32"
          opacity="0.2"
          fill="#6C63FF"
          enable-background="new    "
          width="65"
          height="60"
        />
        <rect
          x="493.21"
          y="391.32"
          opacity="0.2"
          fill="#6C63FF"
          enable-background="new    "
          width="65"
          height="60"
        />
        <rect
          x="386.71"
          y="391.32"
          opacity="0.2"
          fill="#6C63FF"
          enable-background="new    "
          width="65"
          height="60"
        />
      </g>
      <rect
        x="617.55"
        y="270.24"
        opacity="0.2"
        fill="#6C63FF"
        enable-background="new    "
        width="191.33"
        height="140.83"
      />
      <g id="bars-2">
        <rect x="637.71" y="298.82" fill="#EDEDF4" width="151" height="9" />
        <rect x="637.71" y="317.49" fill="#EDEDF4" width="151" height="9" />
        <rect x="637.71" y="336.16" fill="#EDEDF4" width="151" height="9" />
        <rect x="637.71" y="354.82" fill="#EDEDF4" width="151" height="9" />
        <rect x="637.71" y="373.49" fill="#EDEDF4" width="151" height="9" />
      </g>
      <rect
        x="640.88"
        y="427.07"
        fill="#6C63FF"
        width="144.67"
        height="25.33"
      />
      <rect x="633.15" y="511.47" fill="#6C63FF" width="97.01" height="16.99" />
    </svg>
    <div
      class="text-h4 text-center mt-4 font-weight-bold secondary--text"
      id="title"
    >
      Web Apps
    </div>
    <div class="text-h5 text-center text--disabled" id="text">
      State of art public facing apps with blazing-fast performance, mobile
      friendly and quality UI/UX having the latest and best components to ensure
      a flawless experience for end-user
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tl: null,
      textTl: null,
    }
  },

  methods: {
    setup() {
      this.tl = this.gsap.timeline({ paused: true })
      this.tl
        .from('#web-apps #bars-1 *', {
          scaleX: 0.5,
          opacity: 0.1,

          stagger: {
            amount: 0.2,
            grid: 'auto',
            from: 'start',
          },
        })
        .from('#web-apps #bars-2 *', {
          scaleX: 0.5,

          stagger: {
            amount: 0.2,
            grid: 'auto',
            from: 'start',
          },
        })
        .to('#web-apps #headlines *', {
          scaleX: 0.6,
          transformOrigin: '50% 50%',
          opacity: 0.8,
          duration: 0.2,
          stagger: {
            amount: 0.5,
            grid: 'auto',
            from: 'start',
          },
        })

      this.textTl = this.gsap.timeline({ paused: true })
      this.textTl.from('#web-apps #title', {
        opacity: 0.1,
        y: 30,
        duration: 0.2,
      })
      this.textTl.from('#web-apps #text', {
        opacity: 0.05,
        y: 30,
        duration: 0.3,
      })

      const element = document.getElementById('web-apps')
      element.addEventListener('mouseenter', () => {
        this.tl.play()
        this.textTl.play()
      })
      element.addEventListener('mouseleave', () => {
        this.tl.reverse()
        this.textTl.reverse()
      })
    },
  },

  mounted() {
    this.setup()
  },
}
</script>

<style></style>
