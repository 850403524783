<template>
  <div id="website" class="d-flex flex-column align-center">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="98 0 623 592"
      enable-background="new 98 0 623 592"
      xml:space="preserve"
    >
      <g id="Layer_2">
        <path
          fill="#474157"
          d="M721,36.5C721,18.55,706.449,4,688.5,4l0,0h-558C112.551,4,98,18.55,98,36.5l0,0V438h623V36.5z"
        />
        <path
          fill="#EDEDF4"
          d="M131.549,35.499h557c8.837,0,16,7.164,16,16v345c0,8.836-7.163,16-16,16h-557c-8.836,0-16-7.164-16-16v-345
		C115.549,42.663,122.712,35.499,131.549,35.499z"
        />
        <path
          fill="#C8CAD7"
          d="M504.79,577.42v6.41H315.17v-5.221c32.279-11.959,48.751-47.822,36.791-80.102
		c-5.753-15.527-17.446-28.125-32.501-35.018h184.08c-31.307,14.367-45.04,51.393-30.674,82.699
		C479.288,560.184,490.659,571.307,504.79,577.42z"
        />
        <path
          opacity="0.1"
          enable-background="new    "
          d="M503.54,463.5c-17.14,7.865-29.805,23.057-34.46,41.33H353.92
		c-4.655-18.273-17.32-33.465-34.46-41.33H503.54z"
        />
        <path
          fill="#C8CAD7"
          d="M98,430.831v36c0,19.881,14.55,36,32.5,36h558c17.95,0,32.5-16.119,32.5-36v-36H98z"
        />
        <path
          opacity="0.1"
          enable-background="new    "
          d="M504.79,577.42v6.41H315.17v-5.221c5.198-1.93,10.115-4.545,14.62-7.779h163.4
		C496.811,573.436,500.699,575.645,504.79,577.42z"
        />
        <path
          fill="#C8CAD7"
          d="M262,572.831h296c5.247,0,9.5,4.254,9.5,9.5l0,0c0,5.246-4.253,9.5-9.5,9.5H262c-5.247,0-9.5-4.254-9.5-9.5
		l0,0C252.5,577.084,256.753,572.831,262,572.831z"
        />
      </g>
      <g id="eab38be6-400f-4307-af4c-8f24956b1905">
        <g>
          <rect
            id="f54375ad-557d-4249-95e2-413e25c77bd8"
            x="124.353"
            y="67.422"
            fill="#E5E5E5"
            width="570.566"
            height="334.17"
          />
          <rect
            id="eb84611c-d49d-4ce2-b538-4fee0a7e188d"
            x="140.668"
            y="96.078"
            fill="#FFFFFF"
            width="537.936"
            height="278.808"
          />
          <rect
            id="a33b5263-04c3-449f-a8ad-1420252c1e48"
            x="124.11"
            y="48.115"
            fill="#6C63FF"
            width="570.566"
            height="29.918"
          />
          <ellipse
            id="a01a8c4b-bf8b-4728-9381-d6cbaed15ee5"
            fill="#FFFFFF"
            cx="142.123"
            cy="63.019"
            rx="4.493"
            ry="5.545"
          />
          <ellipse
            id="a70f271b-ee75-4447-9507-72af025f0784"
            fill="#FFFFFF"
            cx="159.175"
            cy="63.019"
            rx="4.493"
            ry="5.545"
          />
          <ellipse
            id="b6ae4294-4107-4abd-ba05-1d458fcbc8d7"
            fill="#FFFFFF"
            cx="176.229"
            cy="63.019"
            rx="4.493"
            ry="5.545"
          />
          <g id="article">
            <path
              id="a98f5d70-28fc-4129-b4f3-463d07786f2f"
              fill="#3F3D56"
              d="M228.801,159.548c-1.224,0-2.215,1.224-2.215,2.734
				s0.992,2.734,2.215,2.734l0,0h60.521c1.223,0,2.215-1.225,2.215-2.734s-0.992-2.734-2.215-2.734H228.801z"
            />
            <path
              id="a5717240-4d8d-4c71-83bc-6deeb889f166"
              fill="#CCCCCC"
              d="M194.573,197.194c-1.224,0-2.215,1.223-2.215,2.733
				c0,1.51,0.991,2.734,2.215,2.734H323.55c1.223,0,2.215-1.224,2.215-2.734c0-1.51-0.992-2.733-2.215-2.733H194.573z"
            />
            <path
              id="f9c6f13d-6e3a-4758-9844-a0f5d5c969ce"
              fill="#CCCCCC"
              d="M194.573,218.945c-1.224,0-2.215,1.224-2.215,2.734
				c0,1.509,0.991,2.734,2.215,2.734H323.55c1.223,0,2.215-1.225,2.215-2.734c0-1.51-0.992-2.734-2.215-2.734H194.573z"
            />
            <path
              id="bf808dfd-2bb2-4793-b7c6-d8671bf7e9b4"
              fill="#CCCCCC"
              d="M194.573,240.695c-1.224,0-2.215,1.224-2.215,2.734
				c0,1.51,0.991,2.734,2.215,2.734H323.55c1.223,0,2.215-1.224,2.215-2.734c0-1.51-0.992-2.734-2.215-2.734H194.573z"
            />
            <path
              id="e27373f7-fd0b-426a-8828-0829b084b689"
              fill="#CCCCCC"
              d="M194.573,262.446c-1.224,0-2.215,1.224-2.215,2.734
				c0,1.51,0.991,2.734,2.215,2.734H323.55c1.223,0,2.215-1.224,2.215-2.734c0-1.51-0.992-2.734-2.215-2.734H194.573z"
            />
            <path
              id="b4c6c7b3-e22e-4fba-a4f6-80d19d07dbd3"
              fill="#CCCCCC"
              d="M194.573,284.197c-1.224,0-2.215,1.224-2.215,2.734
				c0,1.51,0.991,2.733,2.215,2.733H323.55c1.223,0,2.215-1.224,2.215-2.733c0-1.511-0.992-2.734-2.215-2.734H194.573z"
            />
            <path
              id="ebf0dc46-a6ad-47cb-a3de-ddb71e9e94e3"
              fill="#CCCCCC"
              d="M194.573,305.948c-1.224,0-2.215,1.225-2.215,2.734
				c0,1.509,0.991,2.732,2.215,2.732H323.55c1.223,0,2.215-1.224,2.215-2.732c0-1.51-0.992-2.734-2.215-2.734H194.573z"
            />
          </g>
          <path
            id="back"
            fill="#F1F1F1"
            d="M620.051,312.585H424.779c-2.718-0.004-4.92-2.721-4.922-6.075V164.735
			c0.002-3.354,2.205-6.072,4.922-6.076h195.271c2.718,0.004,4.92,2.722,4.923,6.076V306.51
			C624.971,309.865,622.769,312.582,620.051,312.585z"
          />
          <path
            fill="#FFFFFF"
            d="M645.739,345.014H450.467c-2.718-0.005-4.919-2.724-4.922-6.076V197.162
			c0.003-3.354,2.204-6.072,4.922-6.076h195.272c2.717,0.003,4.92,2.721,4.923,6.076v141.776
			C650.659,342.291,648.456,345.009,645.739,345.014z"
          />
          <path
            fill="#3F3D56"
            d="M645.739,347.08H450.467c-3.642-0.005-6.593-3.648-6.598-8.142V197.162
			c0.005-4.495,2.956-8.139,6.598-8.144h195.272c3.642,0.005,6.594,3.648,6.598,8.144v141.776
			C652.333,343.431,649.381,347.075,645.739,347.08z M450.467,193.153c-1.793,0.003-3.245,1.796-3.247,4.009v141.776
			c0.002,2.21,1.454,4.006,3.247,4.006h195.272c1.793,0,3.246-1.796,3.248-4.006V197.162c-0.002-2.213-1.455-4.006-3.248-4.009
			H450.467z"
          />
          <ellipse
            id="sun"
            fill="#FF6583"
            cx="545.802"
            cy="284.197"
            rx="17.332"
            ry="17.476"
          />
          <path
            id="mount"
            fill="#6C63FF"
            d="M580.528,266.776c-4.134-5.412-11.04-5.663-15.424-0.56c-0.062,0.071-0.122,0.143-0.182,0.216
			l-54.955,67.829c-7.039,8.729-14.388-4.621-19.084-10.007c-11.291-13.782-23.489,12.063-31.079,19.466h176.598v-2.773
			L580.528,266.776z"
          />
        </g>
      </g>
    </svg>
    <div
      class="text-h4 text-center mt-4 font-weight-bold secondary--text"
      id="title"
    >
      Websites
    </div>
    <div class="text-h5 text-center text--disabled" id="text">
      Showcase any idea with full animations and responsiveness across platforms
      powered with server-side rendering keeping in mind SEO practices
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tl: null,
      textTl: null,
    }
  },

  methods: {
    setup() {
      this.tl = this.gsap.timeline({ paused: true })
      this.tl
        .to('#website #back', {
          x: 10,
          y: 5,
          scale: 1.2,
          duration: 0.2,
        })
        .to('#website #back', {
          x: -15,
          y: -10,
          duration: 0.2,
        })
        .to('#website #sun', {
          y: -30,
          scale: 1.2,
          duration: 0.2,
        })
        .to('#website #mount', {
          x: 15,
          duration: 0.2,
        })
        .from('#website #article *', {
          scaleX: 0.5,
          opacity: 0.1,
          duration: 0.5,
          stagger: {
            amount: 0.5,
            grid: 'auto',
            from: 'start',
          },
        })

      this.textTl = this.gsap.timeline({ paused: true })
      this.textTl.from('#website #title', {
        opacity: 0.1,
        y: 30,
        duration: 0.2,
      })
      this.textTl.from('#website #text', {
        opacity: 0.05,
        y: 30,
        duration: 0.3,
      })

      const element = document.getElementById('website')
      element.addEventListener('mouseenter', () => {
        this.tl.play()
        this.textTl.play()
      })
      element.addEventListener('mouseleave', () => {
        this.tl.reverse()
        this.textTl.reverse()
      })
    },
  },

  mounted() {
    this.setup()
  },
}
</script>

<style></style>
